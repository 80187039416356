<template>
  <div id="app" v-if="logged == true">
    <div class="main-wrapper">
      <Menu />
      <div class="page-wrapper">
        <Navbar />
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>
<script>
import axios from "axios";

import Menu from "./components/Menu/Menu.vue";
import Navbar from "./components/Navbar/Navbar.vue";

import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();

import firebase from "firebase/app";
import "firebase/database";

import { firebaseConfig, firebaseLogin } from "../.env";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var fireLogin = firebase.initializeApp(firebaseLogin, "fireLogin");

const userData = JSON.parse(sessionStorage.getItem("userData"));

export default {
  name: "app",
  components: {
    Menu,
    Navbar,
  },
  data: () => ({
    logged: false,
  }),
  methods: {
    isLogged() {
      userData ? (this.logged = true) : (this.logged = false);

      if (this.logged) {
        sessionStorage.clear();
        this.$router.push({ path: "/" });

        axios
          .get(`https://auth.foodservice.com.ar/?type=user&dni=${userData.leg_numdoc}&access_token=1234567`)
          .then((response) => {
            sessionStorage.setItem(
              "userPermisos",
              JSON.stringify(response.data[0].Permisos)
            );
            sessionStorage.setItem(
              "userData",
              JSON.stringify(response.data[0].datos)
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push("/Login");
      }
    },
    logout() {
      sessionStorage.removeItem("userData");

      this.$router.push("/login");
    },
  },
  computed: {
    loggedUser() {
      const token = sessionStorage.getItem("userData");
      const payload = JSON.parse(atob(token.split(".")[1]));
      // console.log(payload);
      return payload;
    },
  },
  mounted() {
    this.isLogged();
  },
};
</script>

<style>
#logotipo {
  width: 400px;
  margin-left: 35%;
  margin-top: 10%;
}

.custom-select {
  font-size: 0.8rem;
}

.jumbotron {
  background: linear-gradient(60deg, #7700d9, #0087ff 60%, #1ff);
  color: #fff;
}

/* A continuación se pisan los estilos de la plantilla para marcar los links activos */

.sidebar .sidebar-body .nav .nav-item.active .nav-link::before {
  content: none;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link {
  color: #000;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link .link-icon {
  color: #000;
}

/* Luego se agregan propiedades similares a las pisadas para que Vue marque los activos mediante el router */

.router-link-exact-active {
  /* font-weight: 700; */
  color: #727cf5 !important;
}

.router-link-exact-active .link-icon {
  /* font-weight: 700; */
  color: #727cf5 !important;
}

.router-link-exact-active::before {
  content: "";
  width: 3px;
  height: 26px;
  background: #727cf5;
  position: absolute;
  left: -25px;
}

/* input group */
.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
  padding: 0.5rem 0.75rem;
  color: #fff;
  border: #282f3ae6 !important;
}

.input-group-text {
  background-color: rgb(114, 124, 245);
}

.input-group-prepend .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
}
.input-group-append .input-group-text {
  border-radius: 0 0.25rem 0.25rem 0;
}

/* Multiselect */
.multiselect__tags {
  border-color: #282f3ae6 !important;
}

.multiselect__content-wrapper {
  border-color: rgba(40, 47, 58, 0.9) !important;
}

.multiselect__tag-icon::after {
  color: rgb(253, 253, 253) !important;
}

.multiselect__tag {
  background: #4e5bf2 !important;
}

/* input select */

select.form-control,
select,
.email-compose-fields
  .select2-container--default
  select.select2-selection--multiple,
.select2-container--default select.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.select2-search__field,
select.typeahead,
select.tt-query,
select.tt-hint {
  color: #282f3ae6 !important;
}

.table-title {
  position: relative;
  top: 24px;
}

.pointer {
  cursor: pointer;
}

/* form control */
.form-control:focus-visible,
select:focus-visible,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:focus-visible,
.select2-container--default .select2-selection--single:focus-visible,
.select2-container--default
  .select2-selection--single
  .select2-search__field:focus-visible,
.typeahead:focus-visible,
.tt-query:focus-visible,
.tt-hint:focus-visible {
  color: #282f3ae6;
  text-shadow: none;
}
</style>
