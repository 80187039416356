<template>
    <div class="page-content">
      <!-- Breadcrumb -->
      <nav class="page-breadcrumb" id="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">{{breadcrumbA}}</li>
          <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
        </ol>
      </nav>
      <!-- Card view title and description -->
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h6 class="card-title">{{ breadcrumbB }}</h6>
              <div class="d-flex align-items-center justify-content-between">
                <p class="card-description w-50 p-0 m-0">{{ descripcion }}</p>
                <!-- <button
                  v-on:click="refrescarTriggers('crear')"
                  type="button"
                  class="btn w-25"
                  :class="{
                    'btn-light': trigger.crear,
                    'btn-success': !trigger.crear,
                  }"
                >
                  {{
                    !trigger.crear
                      ? "Añadir una nueva ruta"
                      : "Cancelar la creación"
                  }}
                </button> -->
                <div class="">
                  <button
                    v-on:click="refrescarTriggers('listar')"
                    type="button"
                    class="btn btn-light"
                    v-if="trigger.crear"
                  >Cancelar la creación</button>
                  <button
                    v-on:click="refrescarTriggers('crear')"
                    type="button"
                    class="btn  btn-success ml-1"
                    v-if="!trigger.crear"
                  >Añadir sector</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12" v-if="trigger.crear">
          <div class="card">
            <div class="card-body">
              <crear-sector @cerrar="cerrar($event)"></crear-sector>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="trigger.editar">
          <div class="card">
            <div class="card-body">
              <editar-sector :sectorOriginal="seleccion" @cerrar="cerrar($event)"></editar-sector>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="trigger.listar">
          <div class="card">
            <div class="card-body">
              <lista-sectores :sectores="sectores" @cerrar="cerrar($event)" @editar="realizarAccion($event,'editar')"></lista-sectores>
            </div>
          </div>
        </div>
      </div>



    </div>
</template>
<script>
import CrearSector from '@/components/Sector/forms/CrearSector.vue';
import EditarSector from '@/components/Sector/forms/EditarSector.vue';
import ListaSectores from '@/components/Sector/ListaSectores.vue';

import {getDataFrom} from '@/utils';
import {url} from '../../.env';

export default {
  name: 'Sectores',
  components: {
    CrearSector,
    ListaSectores,
    EditarSector
  },
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Sectores',
      descripcion:'Se despliegan los sectores y es posible administrarlos.',
      sectores:null,
      trigger: {
        crear: false,
        editar: false,
        listar: false,
      },
      seleccion: null,
  
  }),
  methods: {
    refrescarTriggers(activo) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(activo!=undefined) this.trigger[activo] = true
    },
    cerrar(actualizar) {
        this.refrescarTriggers('listar');
        if (actualizar) {
            // Obtener devuelve como promesa truo o false en caso de devolver datos.
            this.trigger.listar = false;
            // TODO Mejorar la logica, se deberia crear la funcion obtener para llamar.
            
            getDataFrom('sectores',url).then((response)=>{
              if(response!=null){
                this.sectores = response;
                this.trigger.listar = true;
              }else {
                alert("Ocurrio un error, actualice la página");
                console.warn("No se obtuvo ningún resultado a través de la api sectores")
              }
            })
            .catch((err)=>{
              alert("Ocurrio un error, actualice la página");
              console.warn(err)
            })
            this.obtener('rutas').then(()=>{
                this.trigger.listar = true;
            });
        }
    },
    // TODO generalizar metodo junto a refrescar
    realizarAccion(seleccion,accion){
      this.seleccion = seleccion;
      this.refrescarTriggers(accion);
    },

    
  },
  mounted(){
    getDataFrom('sectores',url).then((response)=>{
      if(response!=null){
        this.sectores = response;
        this.trigger.listar = true;
      }else {
        alert("Ocurrio un error, actualice la página");
        console.warn("No se obtuvo ningún resultado a través de la api sectores")
      }
    })
    .catch((err)=>{
      alert("Ocurrio un error, actualice la página");
      console.warn(err)
    })



    // this.obtener('sectores').then((result)=>{
    //   this.trigger.listar = true
    // })



  }
};

</script>