<template>
    <div v-if="trigger.save">
        <div class="w-50 py-5 mx-auto text-center">
            <div class="d-flex justify-content-center">
                <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <h4 class="mt-3">Modificando marca</h4>
            <p>Sea paciente, no demora mucho</p>
            <!-- Spinner de carga -->
        </div>
    </div>
    <div v-if="trigger.show">
        <div class="w-75 py-5 mx-auto text-center text-success">
            <h4>&check; Marca modificada correctamente</h4>
            <p class="text-dark">Serás redirigido en 1 segundos</p>
        </div>
    </div>
    <form v-if="trigger.write">
        <h4 class="mb-3">Agregar una nueva marca</h4>
        <div class="form-row">
            <div class="col-12 col-md-4 mb-3">
                <div class="form-label">Nombre</div>
                <input type="text" class="form-control" v-model="marca.nombre">
                <div class="text-danger mt-1" v-if="error.marca.nombre.length">&otimes; {{error.marca.nombre}}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <div class="form-label">Responsable</div>
                <input type="text" class="form-control" v-model="marca.responsable">
                <div class="text-danger mt-1" v-if="error.marca.responsable.length">&otimes; {{error.marca.responsable}}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <div class="form-label">Teléfono</div>
                <input type="text" class="form-control" v-model="marca.telefono">
                <div class="text-danger mt-1" v-if="error.marca.telefono.length">&otimes; {{error.marca.telefono}}</div>
            </div>
            <div class="col-12 col-md-6 mb-3">
                <div class="form-label">Correo electrónico</div>
                <input type="text" class="form-control" v-model="marca.email">
                <div class="text-danger mt-1" v-if="error.marca.email.length">&otimes; {{error.marca.email}}</div>
            </div>
            <div class="col-12 col-md-6 mb-3">
                <div class="form-label">Domicilio</div>
                <input type="text" class="form-control" v-model="marca.domicilio">
                <div class="text-danger mt-1" v-if="error.marca.domicilio.length">&otimes; {{error.marca.domicilio}}</div>
            </div>
            <div class="col-12 mt-4 text-right">
                <button class="btn btn-light" @click.prevent="$emit('cerrar',false)">Cancelar</button>
                <button class="btn btn-success ml-2" @click.prevent="agregarObservacion()">Modificar</button>
            </div>
        </div>
    </form>	
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

import { isEmpty,isNum } from "@/utils";


export default {   
	name: 'EditarMarca',
    props:{marcaEdicion:Object},
    data() {
        return {
			marca: {
                nombre: null,
                telefono:null,
                email:null,
                domicilio:null,
                responsable:null,
                timestamp: new Date().getTime()
            },
			
			error:{
                marca: {
                    nombre: '',
                    telefono:'',
                    email:'',
                    domicilio:'',
                    responsable: ''
                },

            },
            trigger: {
                write: true,
                save: false,
                show: false,
            }
        }
    },
    methods: {
		agregarObservacion(){
			
            if(!this.valido()) return null;

            this.trigger.write = false;
            this.trigger.save = true;

            delete this.marca.id;

            firebase.database().ref(`/articulos_marca/${this.marcaEdicion.id}`)
            .update(this.marca)
            .then(()=>{
                this.trigger.save = false;
                this.trigger.show = true;
                setTimeout(() => {
                    this.$emit('cerrar',true);
                }, 2000);
                
            })
            .catch((err)=>{
                console.warn(err);
                alert("Se produjo un error al crear el marca, pruebe actualizar la página y reintentar.")
            })
		},
        valido(){
            let valido = true;
            Object.keys(this.marca).forEach((property)=>{
                this.error.marca[property] = '';
                if(isEmpty(this.marca[property])){
                    if(property=='responsable'){
                        this.marca.responsable = 'Desconocido';
                    }else {
                        valido = false;
                        this.error.marca[property] = 'El campo es obligatorio';
                    }
                }else if(property == 'email'){
                    if (!/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.marca.email)) {
                        this.error.marca.email = 'El correo no es valido. Ej: nombre@dominio.com';
                        valido = false;
                    }
                }else if(property=='telefono'){
                    if(!isNum(this.marca.telefono)){
                        this.error.marca.telefono = 'El telefono solo debe contener números';
                        valido = false;
                    }
                }
            });

            return valido
        }
		
    },
	mounted() {
        this.marca = JSON.parse(JSON.stringify(this.marcaEdicion));
    },
}
</script>

<style scoped>

</style>