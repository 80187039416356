<template>
		<nav class="sidebar">
      <div class="sidebar-header">
        <a href="" class="sidebar-brand">
          <span>FS</span> CALIDAD
        </a>
        <div class="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
        <ul class="nav">
          <li class="nav-item nav-category" v-if="role.editor">Temperaturas</li>
          <li class="nav-item" v-if="role.editor">
            <router-link :to="{ name: 'Temperaturas'}" class="nav-link">
              <i class="link-icon" data-feather="thermometer"></i>
              <span class="link-title">Temperaturas</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="role.editor">
            <router-link :to="{ name: 'Registros'}" class="nav-link">
              <i class="link-icon" data-feather="clipboard"></i>
              <span class="link-title">Registros</span>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :to="{ name: 'Sectores'}" class="nav-link">
              <i class="link-icon" data-feather="grid"></i>
              <span class="link-title">Sectores</span>
            </router-link>
          </li> -->
          <!-- <li class="nav-item nav-category">Máquinas</li>
          <li class="nav-item">
            <router-link :to="{ name: 'Maquinas'}" class="nav-link">
              <i class="link-icon" data-feather="settings"></i>
              <span class="link-title">Tablero de control</span>
            </router-link>
          </li> -->
          <li class="nav-item nav-category">Documentación</li>
          <li class="nav-item">
            <router-link :to="{ name: 'Articulos'}" class="nav-link">
              <i class="link-icon" data-feather="file-text"></i>
              <span class="link-title">Artículos</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Marcas'}" class="nav-link">
              <i class="link-icon" data-feather="tag"></i>
              <span class="link-title">Marcas</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="role.editor">
            <router-link :to="{ name: 'Rubros'}" class="nav-link">
              <i class="link-icon" data-feather="package"></i>
              <span class="link-title">Rubros</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
</template>

<script>


const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));


export default {
  name: 'Menu',
  data() {
    return {
      role:{editor:false},
    }
  },
  mounted() {
    let permission = userPermission.find(record => record.ModulosAccess === "Calidad")
    this.role.editor = (permission!=undefined) ? permission.Editor : false;
  },
    methods:{
     isRoll(modulo){
        var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
        return status;
      }
  }
};







</script>