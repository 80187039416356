<template>
  <h4 class="mb-3">Lista de sectores existentes</h4>
  <div class="table-responsive">
    <table id="tabla" class="table">
      <thead>
        <tr>
          <th >ID</th>
          <th>Nombre</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(sector,i) in sectores" :key="sector.id">
          <td><div class="badge badge-light">{{sector.id}}</div></td>
          <td>{{sector.nombre}}</td>
          <td>
              <button class="btn btn-primary mr-2" @click.prevent="$emit('editar',{fireId:i,sector:sector})">Editar sector</button>
              <button class="btn btn-success">Agregar máquina</button>
          </td>
          
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery';
import {tableConfig} from '../../../.env';
export default {
    name:'ListaSectores',
    props:{sectores:Object},
    mounted(){
      $('#tabla').dataTable(tableConfig)
    }
}
</script>

<style>

</style>