<template>
  <h4 class="mb-3" v-if="!detalle">Lista de artículos existentes</h4>
  <div class="form-row" v-if="articulos">
    <div class="col-12">
      <p>Puede filtrar los resultados mediante rubros y sectores</p>
    </div>
    <div class="col-5 mb-3">
        <select v-model="filtroRubro" class="form-select">
            <option :value="null" disabled>Seleccione un rubro</option>
            <option :value="i" v-for="(rubro,i) in rubros" :key="i">{{rubro.nombre}}</option>
        </select>
    </div>
    <div class="col-5 mb-3" v-if="filtroRubro">
        <select v-model="filtroRubroSector" class="form-select">
            <option :value="null" disabled>Seleccione un sector</option>
            <option :value="i" v-for="(sector,i) in rubros[filtroRubro].sectores" :key="i">{{sector.nombre}}</option>
            <option :value="null">Sin sector asignado</option>
        </select>
    </div>
    <div class="col-2 mb-3" v-if="filtroRubro">
        <button class="btn btn-primary" @click.prevent="limpiarFiltros()">Limpiar filtros</button>
    </div>
  </div>
  <div class="table-responsive">
    <table id="tabla" class="table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Tipo</th>
          <th v-if="!detalle">Marca</th>
          <th>Vencimiento aprobación</th>
          <th>Cumplimientos</th>
          <th v-if="!detalle"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(articulo,i) in filtroArticulos()" :key="i">
          <td>{{articulo.nombre}}</td>
          <td>
            <div class="badge badge-primary" @click="seleccionRubro(rubros[articulo.rubro].nombre, (articulo.rubro_sector!=undefined)?rubros[articulo.rubro].sectores[articulo.rubro_sector].nombre:null)">
              {{capitalizeR(rubros[articulo.rubro].nombre)}}
              |
              {{(articulo.rubro_sector!=undefined)?rubros[articulo.rubro].sectores[articulo.rubro_sector].nombre:'Indefinido'}}
            </div>
          </td>
          <td v-if="!detalle">{{marcas[articulo.marca_articulo].nombre}}</td>
          <td class="">
            <div class="badge" :class="getColorIfIrregular(articulo.id)" v-if="irregulares!=null">{{dateFormateR(articulo.vencimiento)}}</div>
          </td>
          <td>          
            <div class="badge badge-danger pointer" v-if="articulo.documentacion==undefined">0</div>
            <Popper arrow placement="top" v-else>
              <div class="badge badge-primary pointer">{{Object.keys(articulo.documentacion).length}}</div>
              <template #content="{ close }">
                <div class="d-flex align-items-center justify-content-between bg-light rounded pr-2">
                  <div>
                    <div class="text-wrap pl-2 my-1" style="max-width: 200px;">
                      <div v-for="(ap,i) in Object.keys(articulo.documentacion)" :key="i">&bull; {{capitalizeAndNormalize(ap)}}</div>
                    </div>
                  </div>
                  <Button @click="close" class="border-0 bg-transparent text-dark ml-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </Button>
                </div>
              </template>
            </Popper>
          </td>
          <td v-if="!detalle">
              <button class="btn btn-primary mr-2" @click.prevent="prepararEditar(articulo,i)">Editar articulo</button>
              <button class="btn btn-danger" @click.prevent="eliminar(articulo)">Eliminar artículo</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";

import {tableConfig,url} from '../../../.env';
import {dateFormat,capitalize,setProp,getDataFrom} from '@/utils';
import Popper from "vue3-popper";
export default {
    name:'ListaArticulos',
    props:{articulos:Object,rubros:Object,marcas:Object,detalle:Boolean},
    components:{Popper},
    data(){
      return {
        filtroRubro:null,
        filtroRubroSector:null,
        irregulares:null,
      }
    },
    mounted(){
      $('#tabla').dataTable(tableConfig);
      getDataFrom('articulos_irregulares',url).then((data)=>{
        if(data){
          this.irregulares = data;
        }
      })
    },
    methods: {
      getColorIfIrregular(id){
        if (this.irregulares[id]!=undefined) {
          if (this.irregulares[id].vencido) {
            return 'vencido';
          }else if (this.irregulares[id].ultimo_aviso) {
            return 'ultimo-aviso';
          }else if (this.irregulares[id].primer_aviso) {
            return 'primer-aviso';
          } else {
            return 'primer-aviso';
          }
        }
        return 'regular';
      },
      dateFormateR(date){return dateFormat(date)},
      capitalizeR(str){return capitalize(str)},
      capitalizeAndNormalize(str){return capitalize(str.replaceAll('_',' '))},
      filtroArticulos(){
        if (this.articulos) {
          return Object.keys(this.articulos).flatMap((art)=>{
            if(this.filtroRubro && this.articulos[art].rubro != this.filtroRubro) return []
            if(this.filtroRubroSector && this.articulos[art].rubro_sector != this.filtroRubroSector) return []
  
            // Se hace un merge del id dentro del articulo
            let articulo = this.articulos[art];
            setProp(articulo,'id',art)
            return articulo
          })
        }
      },
      eliminar(articulo){
        Swal.fire({
          title: 'Estas eliminando un artículo',
          text: "¿Estas realmente seguro?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
          if (result.isConfirmed) {
            firebase.database().ref(`articulos_eliminados`).push(articulo).then(()=>{
              firebase.database().ref(`articulos/${articulo.id}`).remove().then(()=>{
                Swal.fire(
                  'Eliminado!',
                  'El articulo fué eliminado.',
                  'success'
                ).then(()=>{
                  this.$emit('actualizar');
                })

              }).catch((err)=>{
                alert('Se produjo un error, se recomiendo actualizar');
                console.warn(err)
              })
            }).catch((err)=>{
              alert('Se produjo un error, se recomiendo actualizar');
              console.warn(err)
            })



          }
        })

      },
      seleccionRubro(rubroNombre,sectorNombre){
        // console.log(rubroNombre);
        // console.log(sectorNombre);
        let indexRubro = null;
        Object.keys(this.rubros).some((el)=>{
          // console.log(this.rubros[el].nombre===rubroNombre);
          if(this.rubros[el].nombre===rubroNombre){
            indexRubro = el;
            return true 
          }
        })
        if (this.rubros[indexRubro] != undefined) {
          this.filtroRubro = indexRubro;
        }  

        if(sectorNombre!=null){
          let indexSector = null;
          Object.keys(this.rubros[indexRubro].sectores).some((el)=>{
            // console.log(this.rubros[el].nombre===rubroNombre);
            if(this.rubros[indexRubro].sectores[el].nombre===sectorNombre){
              indexSector = el;
              return true
            }
          })
          if (this.rubros[indexRubro].sectores[indexSector] != undefined) {
            this.filtroRubroSector = indexSector;
          }
        }
      },
      limpiarFiltros(){
        this.filtroRubro = null;
        this.filtroRubroSector = null;   
      },
      prepararEditar(articulo,i){
        let articuloConId = articulo;
        setProp(articuloConId,'id',i);
        this.$emit('editar',articuloConId);
      },
    }

}
</script>

<style>
.vencido {
  color: #fff;
  background-color: #ff3366;
}

.ultimo-aviso {
  color: #212529;
  background-color: #fbbc06;
}

.primer-aviso {
  color: #212529;
  background-color: yellow;
}

.regular {
  color: #fff;
  background-color: #10b759;
}

  .text-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  :deep(.popper) {
    background: #2f2f2f;
    padding: 10px;
    border-radius: 8px;
    color: #fff;
}

  :deep(.popper #arrow::before) {
    background: #2f2f2f;
  }

  :deep(.popper:hover),
  :deep(.popper:hover > #arrow::before) {
    background: #2f2f2f;
  }

</style>