<template>
  <lista-carga :card="false" v-if="trigger.load"></lista-carga>
  <div v-if="trigger.lista">
    <h4 class="mb-3">Lista de marcas existentes</h4>
    <div class="table-responsive">
      <table id="tabla" class="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Responsable</th>
            <th>Teléfono</th>
            <th>Email</th>
            <th>Dirección</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(marca,i) in marcas" :key="marca.id">
            <td>{{marca.nombre}}</td>
            <td>{{marca.responsable}}</td>
            <td>{{marca.telefono}}</td>
            <td>{{marca.email}}</td>
            <td>{{marca.domicilio}}</td>
            <td>
                <button class="btn btn-primary mr-2" @click.prevent="prepararEdicion(marca,i)" v-if="role.editor">Editar</button>
                <button class="btn btn-primary" @click.prevent="listarArticulosDeMarca(marca,i)">
                  <!-- // TODO Terminar el loader y disabled -->
                  <div :ref="`load-${i}`" class="spinner-border text-light" style="width:12px;height:12px;display:none" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  Ver articulos
                </button>
            </td>       
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-if="trigger.articulos">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="mb-3">Lista de articulos de la marca {{seleccion.marca.nombre}}</h4>
      <div class="btn btn-light" @click.prevent="cerrarDetalle()">← Volver a marcas</div>
    </div>
    <lista-articulos :rubros="seleccion.rubros" :marcas="marcas" :articulos="seleccion.articulos" :detalle="true"></lista-articulos>
  </div>
</template>

<script>
import $ from 'jquery';
import {tableConfig,url} from '../../../.env';
import {setProp,getDataFrom} from '@/utils.js';
import ListaArticulos from './ListaArticulos.vue';
import ListaCarga from '@/components/ListaCarga.vue';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name:'ListaMarcas',
    props:{marcas:Object},
    data(){
      return {
        trigger:{
          articulos:false,
          load:false,
          lista:true
        },
        seleccion:{
          articulos:null,
          marca:null,
          rubros:null
        },
        role:{editor:false},
      }
    },
    components:{ListaArticulos,ListaCarga},
    mounted(){
      let permission = userPermission.find(record => record.ModulosAccess === "Calidad")
      this.role.editor = (permission!=undefined) ? permission.Editor : false;

      $('#tabla').dataTable(tableConfig);
    },
    methods:{
      cerrarDetalle(){
        this.seleccion = {
          articulos:null,
          marca:null,
          rubros:null
        }

        this.trigger.articulos = false;
        this.trigger.lista = true;
        $('#tabla').dataTable().fnDestroy();
        this.$nextTick(()=>{
          $('#tabla').DataTable(tableConfig);
        })
      },
      prepararEdicion(marca,i){
        let marcaConId = marca;
        setProp(marcaConId,'id',i);
        this.$emit('editar',marcaConId);
      },
      listarArticulosDeMarca(marca,i){
        this.trigger.lista = false;
        this.trigger.load = true;
        this.seleccion.marca = marca;
        getDataFrom(`articulos.json?orderBy="marca_articulo"&equalTo="${i}"`,url,true).then((data)=>{
          this.seleccion.articulos = data;
          if (data) {
            getDataFrom('articulos_rubro',url).then((dataE)=>{
              this.seleccion.rubros = dataE;
              this.trigger.load = false;
              this.trigger.articulos = true;
            });
          }// TODO Manejar excepcion sin articulos
        }).catch((err)=>{
          alert("Se produjo un error");
          console.warn(err);
        })
      }
    }
}
</script>

<style>

</style>