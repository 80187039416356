// Estados: dev | prod
const appEnv = 'prod';

const dbUrl = {
    dev: 'https://fs-calidad-default-rtdb.firebaseio.com',
    prod: 'https://fs-calidad.firebaseio.com'
}

export const url = dbUrl[appEnv];

const config = {
    dev: {
        // Desarrollo
        apiKey: "AIzaSyBixp70SwCeHfledRHoo_OQeFE6zKgiR5M",
        authDomain: "fs-calidad.firebaseapp.com",
        databaseURL: "https://fs-calidad-default-rtdb.firebaseio.com",
        projectId: "fs-calidad",
        storageBucket: "fs-calidad.appspot.com",
        messagingSenderId: "973789985987",
        appId: "1:973789985987:web:cad133d290709364923676"
    },
    prod: {
        // Produccion
        apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
        authDomain: "cantina-89c6a.firebaseapp.com",
        databaseURL: "https://fs-calidad.firebaseio.com",
        projectId: "cantina-89c6a",
        storageBucket: "cantina-89c6a.appspot.com",
        messagingSenderId: "353062217145",
        appId: "1:353062217145:web:8849a49a1ec52077"
    }
}

export var firebaseConfig = config[appEnv];

export var firebaseLogin = {
    apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
    authDomain: "cantina-89c6a.firebaseapp.com",
    databaseURL: "https://aplicativos.firebaseio.com",
    projectId: "cantina-89c6a",
    storageBucket: "cantina-89c6a.appspot.com",
    messagingSenderId: "353062217145",
    appId: "1:353062217145:web:8849a49a1ec52077"
};

// TODO EN DESUSO
// Apis
export var apis = {
  'camaras': `${dbUrl[appEnv]}/camaras.json`,
  'centrales_frio': `${dbUrl[appEnv]}/centrales_frio.json`,
  'registro_temperaturas': `${dbUrl[appEnv]}/registro_temperaturas.json`,
  'observaciones': `${dbUrl[appEnv]}/observaciones.json`,
}
// TODO EN DESusO
export function prefabQ(api,parametro){
    switch (api) {
        case 'chofer':
            return `${dbUrl[appEnv]}/choferes/${parametro}.json`
        case 'vehiculo':
            return `${dbUrl[appEnv]}/vehiculos/${parametro}.json`
        case 'empresa':
            return `${dbUrl[appEnv]}/empresas/${parametro}.json`
        case 'vinculos':
            return `${dbUrl[appEnv]}/vinculos/${parametro}.json`
        default:
            return "ninguna api encontrada"
    }
}



  export var tableConfig = {
    "language": {
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoPostFix": "",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "loadingRecords": "Cargando...",
        "lengthMenu": "Mostrar _MENU_ registros",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
        },
        "processing": "Procesando...",
        "search": "Buscar:",
        "searchPlaceholder": "Término de búsqueda",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
    },
    "scrollX": false, 
    "search": {regex:true},
    dom: 'Bfrtip',
    responsive: false,
    nowrap: false,
    buttons: [
        'copy',
        'excel',
        'csv',
        'pdf'
    ]
};