<template>
	
		<!-- Template content  -->
		<div class="row">
			<div class="col-12">
				<label class="form-label">Observacion</label>
				<div class="d-flex">
					<div class="w-75 pr-3">
						<input type="text" class="form-control" v-model="observacionNueva" @input="error=false" placeholder="Nueva observacion"/>
						<span class="text-danger" v-if="error">· Imposible agregar una observación vacia</span>
					</div>
					<div class="w-25">
						<button class="btn btn-lg btn-success w-100" @click.prevent="agregarObservacion()">Agregar</button>
					</div>
				</div>
			</div>
			<div class="col-12 mt-2">
				<div class="alert alert-success" v-if="success">
					Observacion agregada correctamente
				</div>
			</div>
			<div class="col-12 mt-3">
				<label class="form-label">Observaciones existentes</label>
				<div 
					v-for="(observacion,i) in observaciones" :key="i"
					class="d-flex align-items-center p-3 mb-2 border border-light rounded">
					<div class="w-75 pr-3">
						· <span>{{observacion.nombre}}</span>
					</div>
					<div class="w-25">
						<button class="btn btn-sm btn-danger w-100" @click.prevent="removerObservacion(observacion.fireId)">Quitar</button>
					</div>
				</div>

			</div>
			
		</div>
	
</template>
<script>
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

import { apis } from "../../../.env";


export default {   
	name: 'NuevaObservacion',
    data() {
        return {
			observacionNueva: null,
			observaciones: [],
			error:false,
			success: false,
        }
    },
    methods: {
		agregarObservacion(){
			if(this.observacionNueva==null || this.observacionNueva.length == 0){
				this.error = true;
				return null;
			}

			firebase.database().ref('/observaciones').push({
				nombre: this.observacionNueva,
				prioridad: null
			}).then(()=>{
				this.observacionNueva = null;
				this.success = true;
				this.obtenerObservaciones();
				setTimeout(() => {
					this.success = false;
				}, 3000);

			})
		},
		obtenerObservaciones(){
			this.observaciones = [];
			axios
			.get(apis.observaciones)
			.then(response => {
				Object.keys(response.data).forEach((fireId)=>{
					this.observaciones.unshift({
						fireId: fireId,
						nombre: response.data[fireId].nombre
					});

				})
			})
		},
		removerObservacion(fireId){
			console.log('/observaciones/'+fireId);
			firebase.database().ref('/observaciones/'+fireId).remove()
			.then(()=>{
				this.obtenerObservaciones();
			})
			.catch((error)=>{
				if (error) {
					alert("Se produjo un error al eliminar")
					console.log(error);
				}
			})
		}
		
    },
	mounted() {
		this.obtenerObservaciones();
    },
}
</script>

<style scoped>
.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
</style>