<template>
    <div class="page-content">
      <nav class="page-breadcrumb" id="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">{{breadcrumbA}}</li>
          <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
        </ol>
      </nav>
      
      <div class="row">
        <div class="col">
          <div class="jumbotron">
            <h1 class="display-4">{{saludo}} {{nombre.split(' ')[0]}}!</h1>
            <p class="lead">Sistema integral de aplicativos de Food Service America</p>
          </div>  
        </div>  
      </div> 

      <!-- <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h4>Prueba</h4>
              <input-file :titulo="'Agregar documento'" :multiple='true' :error="{estado: false,mensaje:''}" @seleccion="asignarDocumentos('aprobacion',$event)" @imagen-removida="removerImagen(producto.documentacion.imagenes,$event)"></input-file>
            </div>
          </div>  
        </div>  
      </div>  -->
    </div>
</template>
<script>
// import InputFile from '@/components/InputFile.vue';
const userData = JSON.parse(sessionStorage.getItem('userData'));
export default {
  name: 'Dashboard',
  // components:{InputFile,},
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Dashboard',
      descripcion:'Portal Dashboard',
      saludo: null,
      nombre: userData.leg_nombre.charAt(0).toUpperCase() + userData.leg_nombre.slice(1).toLowerCase(),

      producto: {
        documentacion:{
          aprobacion:{
            fecha_vencimiento: null,
            imagenes:['hola']
          }
        }
      }
   }),
   methods:{
     asignarDocumentos(doc,images){
       this.producto.documentacion[doc].imagenes = images;
      //  console.log(image);
      //  console.log(obj);
     },
   },
   mounted(){
     var fecha = new Date();
     var hora = fecha.getHours();
      if(hora >= 0 && hora < 12){
          this.saludo = "Buenos días";
        }

        if(hora >= 12 && hora < 18){
          this.saludo = "Buenas tardes";
        }

        if(hora >= 18 && hora < 24){
          this.saludo = "Buenas noches";
        }
    }

};

</script>