import axios from "axios";

/**
 * Devulve en una promesa la entidad consultada a través de la api
 * @param {String} entity entidad buscada siguiendo path de firebase.
 * @param {String} api donde impactar la consulta. 
 * @returns una promesa resolve (data[] o null)
 */

export function getDataFrom(entity, api, isQuery){
    return new Promise((resolve, reject) =>{
        let url;
        if(isQuery){
            url = `${api}/${entity}`;
        }else {
            url = `${api}/${entity}.json`;
        }
        axios
        .get(url)
        .then((response)=>{
            // console.log(response);
            if(response.status == 200 && response.data != null || response.data != []){
                resolve(response.data);
            }else{
                console.log(`no se encontro <${entity}>`);
                reject(null);
            }
        })
        .catch((error)=>{
            reject(error);
        })
    })
}

/**
 * Comprueba que el valor tenga valor y no sea null
 * @param property de un objeto
 * @returns true si es vacio
 */
 export function isEmpty(property){
    return property == null || property == '';
}

/**
 * Comprueba que la cadena dada no contenga mas que números
 * @param property un string a comprobar
 * @returns true si es numerico
 */
 export function isNum(property){
    return /^\d+$/.test(property);
}


/**
 * Retorna un string con la primera letra mayuscula.
 * @param {String} word palabra a tranformar
 * @returns Word (notese la w mayuscula)
 */
export function capitalize(word) {
    if (typeof word === 'string') {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    return word
}

/**
 * Define propiedades a objetos en caso de no existir, acorta las lineas al crear estas nuevas propiedades,
 * evitanto los parametros writable, enmumerable y configurable
 * @param obj objeto al cual se le agrega la propiedad
 * @param name nombre de la nueva propiedad
 * @param val valor que se setea en la propiedad
 */
 export function setProp(obj,name,val){
    if (!Object.prototype.hasOwnProperty.call(obj, name)) {
        Object.defineProperty(obj, name, {
        value: val,
        writable: true,
        enumerable: true,
        configurable: true
        });
    }
}

/**
 * Convierte la fecha dada al formato utilizado en Argentina DD-MM-AAAA
 * @param {String} initDate fecha para transformar, puede ser un Date o un string con fecha valida
 * @returns String fecha formateada
 */
export function dateFormat(initDate){
    let date = new Date(initDate)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    if(month < 10){
        if(day<10){
            return(`0${day}-0${month}-${year}`)
        }
        return(`${day}-0${month}-${year}`)
    }else{
        if(day<10){
            return(`0${day}-${month}-${year}`)
        }
        return(`${day}-${month}-${year}`)
    }
}

/**
 * Redondea los decimales en forma precisa
 * @param {Int} num numero a redondeas
 * @param {Int} decimals cantidad de decimales
 * @returns numero redondeado
 */
export function precise_round(num,decimals) {
    var sign = num >= 0 ? 1 : -1;
    return (Math.round((num*Math.pow(10,decimals)) + (sign*0.001)) / Math.pow(10,decimals)).toFixed(decimals);
}


import firebase from "firebase/app";
import 'firebase/storage';

export function putBase64(data) {
    return new Promise((resolve,reject)=>{
        var storageRef = firebase.storage().ref().child(`calidad/${new Date().getTime()+'-'+Math.ceil(Math.random()*1000)}`);
        storageRef.putString(data, 'data_url').then(function(snapshot) {
            
            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                resolve(downloadURL);
            }).catch(e=>{reject(e);})

        });  
    })
}


export function removeFile(url) {
    return new Promise((resolve,reject)=>{
        let imageId = url.split('calidad%2F')[1].split('?alt')[0];
        var storageRef = firebase.storage().ref('calidad/');
        // Create a reference to the file to delete
        var file = storageRef.child(imageId);
    
        // Delete the file
        file.delete().then(function() {
            console.log(`Archivo: ${imageId} eliminado`);
            resolve(true)
        }).catch(function(error) {
            reject(error)
        // Uh-oh, an error occurred!
        });
    })

}


export function almacenarImagen(currentFile){
    return new Promise((resolve,reject)=>{
        // File or Blob
        var file = currentFile;
        // Upload file and metadata to the object 'calidad/mountains.jpg'
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('calidad/' + file.name).put(file, {contentType:'image/jpeg'});
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        // Snapshoot actualiza la carga, on lo hace pero snapshoot tiene la data
        function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
        }, function(error) {
            alert("Se produjo un error, actualiza la pagina (tecla: F5), reintenta o comunicate con sistemas");
            switch (error.code) {
                case 'storage/unauthorized':
                    console.log("El usuario no tiene permisos para subir a firestore");
                    reject(error.code)
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    console.log("error desconocido");
                    console.log(error.serverResponse);
                    reject(error.code)
                break;
            }
        }, function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                resolve(downloadURL);
            });
        });
    })

}


