<template>
    <!-- <transition name="modal">
        <div class="card bg-light mb-3" style="max-width: 21rem;">
            <div class="card-header bg-danger text-white">Atención</div>
            <div class="card-body text-right">
                <p class="card-text text-left">Si elimina el rubro se perderán registros de facturas asociadas</p>
            <div class="btn btn-danger">Eliminar
            </div></div>
        </div>
    </transition> -->


    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" ref="alignment-point">
                <div class="modal-container">
                    <div class="card mb-3" ref="main-content" @click="test()">
                        <div class="card-header text-white d-flex align-items-center" :class="'bg-'+type">
                            <slot name="title"></slot>
                        </div>
                        <div class="card-body" style="width:464px">
                            <slot name="body" @response="handleResponse($event)"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name:'ModalPromt',
    props:{type:String},
    data(){
        return {
            decision:false
        }
    },
    methods:{
        handleResponse(e){
            console.log(e);
        },
        responder(eleccion){
            if(eleccion) this.decision = true;
            this.$emit('eleccion',eleccion);
        },
        test(){
            // console.info(document.getElementById('main-content').offsetHeight)
            // console.info(this.$refs['main-content'].offsetHeight)
            // console.log(this.$refs['alignment-point'].style.paddingBottom);
            // document.getElementById('alignment-point').style.marginBottom = `${document.getElementById('main-content').offsetHeight + 20}px`;
        },
    },
    mounted(){
        this.$refs['alignment-point'].style.paddingBottom = `${this.$refs['main-content'].offsetHeight + 20}px`;
        // console.info(document.getElementById('main-content').offsetHeight)
    }
}
</script>

<style scoped>
.card-header {
    border-radius: .6rem .6rem 0 0  !important;
    font-size: 16px;
}

.card {
    max-width: 32rem;
    position: absolute;
    border-radius: .6rem;
    border:0 !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding-bottom:140px;
}

.modal-container {
    width: 32rem;
    margin: 0px auto;
    transition: all 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>