<template>

    <!-- partial:../../partials/_navbar.html -->
      <nav class="navbar">
        <a href="blank-page.html#" class="sidebar-toggler">
          <i data-feather="menu"></i>
        </a>
        <div class="navbar-content">
       
          <ul class="navbar-nav">

            <li class="nav-item">
              <button type="button" class="bg-transparent border-0 text-primary" @click.prevent="logout()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
              </button>
            </li>
           
           <!-- <li class="nav-item dropdown nav-profile">
              <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img :src="profilepic" alt="profile">
              </a>
              <div class="dropdown-menu" aria-labelledby="profileDropdown">
                <div class="dropdown-header d-flex flex-column align-items-center">
                  <div class="figure mb-3">
                    <img :src="profilepic" alt="profile">
                  </div>
                  <div class="info text-center">
                    <p class="name font-weight-bold mb-0">{{name}} {{lastname}}</p>
                    <p class="email text-muted mb-3">{{email}}</p>
                  </div>
                </div>
                <div class="dropdown-body">
                  <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item">
                      <a href="javascript:;" v-on:click="logout()" class="nav-link">
                        <i data-feather="log-out"></i>
                        <span>Log Out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li> -->
          </ul>
        </div>
      </nav>
      <!-- partial -->

</template>

<script>


// const userData = JSON.parse(JSON.parse(sessionStorage.getItem('userData')));


export default {
    name: 'Navbar',
    data: () => ({
      
    //  name: userData.leg_nombre,
    //  lastname:userData.leg_apellido,
    //  email:userData.Leg_mail,
    //  profilepic:"https://produccion.foodservice.com.ar/assets/images/default.png",

    }),
    methods: {
      
      logout() {
        sessionStorage.removeItem('userData')
        
        location.href="/";
      }
    },
  };
</script>