<template>
	<div class="page-content">
		<!-- Breadcrumb -->
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<!-- Page title -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				</div>  
			</div>
			</div>
		</div> 
		<!-- Template content  -->
		<lista-carga v-if="triggers.load"></lista-carga>
		<div class="row" v-else>
		<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
			<div class="card-body">
				<div class="table-responsive">
				<table id="temperaturas" class="table">
					<thead>
					<tr>
						<th>Fecha</th>
						<th>Camara</th>
						<th>0:00</th>
						<th>2:00</th>
						<th>4:00</th>
						<th>6:00</th>
						<th>8:00</th>
						<th>10:00</th>
						<th>12:00</th>
						<th>14:00</th>
						<th>16:00</th>
						<th>18:00</th>
						<th>20:00</th>
						<th>22:00</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(registro,i) in registros" v-bind:key="i">
						<th>{{registro.fecha}}</th>
						<th>{{registro.camara}}</th>
						<th :class="{'text-danger':registro.horas['0:00'].fuera}">{{registro.horas['0:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['0:00'].observacion" @click="abrirModalObservacion(registro,'0:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['2:00'].fuera}">{{registro.horas['2:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['2:00'].observacion" @click="abrirModalObservacion(registro,'2:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['4:00'].fuera}">{{registro.horas['4:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['4:00'].observacion" @click="abrirModalObservacion(registro,'4:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['6:00'].fuera}">{{registro.horas['6:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['6:00'].observacion" @click="abrirModalObservacion(registro,'6:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['8:00'].fuera}">{{registro.horas['8:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['8:00'].observacion" @click="abrirModalObservacion(registro,'8:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['10:00'].fuera}">{{registro.horas['10:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['10:00'].observacion" @click="abrirModalObservacion(registro,'10:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['12:00'].fuera}">{{registro.horas['12:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['12:00'].observacion" @click="abrirModalObservacion(registro,'12:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['14:00'].fuera}">{{registro.horas['14:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['14:00'].observacion" @click="abrirModalObservacion(registro,'14:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['16:00'].fuera}">{{registro.horas['16:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['16:00'].observacion" @click="abrirModalObservacion(registro,'16:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['18:00'].fuera}">{{registro.horas['18:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['18:00'].observacion" @click="abrirModalObservacion(registro,'18:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['20:00'].fuera}">{{registro.horas['20:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['20:00'].observacion" @click="abrirModalObservacion(registro,'20:00')">!</div></th>
						<th :class="{'text-danger':registro.horas['22:00'].fuera}">{{registro.horas['22:00'].temperatura}} <div class="badge badge-pill badge-warning font-weight-bold px-2" v-if="registro.horas['22:00'].observacion" @click="abrirModalObservacion(registro,'22:00')">!</div></th>
					</tr>
					</tbody>
				</table>
				</div>
			</div>
			</div>
		</div>
		</div>
		
	</div>

	<modal-observacion
		v-if="modalObservacion.open"
		:registro="modalObservacion.registro"
		:horaSeleccionada="modalObservacion.hora"
		@cerrar-modal-observacion="cerrarModal($event)"
	></modal-observacion>
</template>
<script>
import axios from 'axios';
// import firebase from "firebase/app";
// import "firebase/database";
import ListaCarga from "../ListaCarga"
import ModalObservacion from "./ModalObservacion"

import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

import { apis } from '../../../.env.js';


export default {   
	name: 'Registros',
	components: {ListaCarga, ModalObservacion},
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Registros de temperaturas',
            descripcion: 'A traves de la búsqueda es posible filtrar los registros por fecha, camara o cualquier información',
			triggers:{load: true},
			registros: [],
			modalObservacion: {
				open: false,
				registro: null,
				hora:null,
			}
        }
    },
    methods: {
		convertirFecha(fecha){
			let array = fecha.split("-");
			return array[2] + "-" + array[1] + "-" + array[0];
		},
		abrirModalObservacion(registro,hora){
			this.modalObservacion.registro = registro; 
			this.modalObservacion.hora = hora;
			this.modalObservacion.open = true;
		},
		cerrarModal(){
			this.modalObservacion.open = false;
			this.modalObservacion.hora = null;
			this.modalObservacion.registro=null;
		},
    },
	mounted() {
		axios
		.get(apis.registro_temperaturas)
		.then(registrosResponse => {
			let registrosPorFecha = registrosResponse.data;
			// console.log(this.registros);
			Object.keys(registrosPorFecha).forEach((fecha)=>{
				var fechaI = fecha;
				var camaraI = null;
				var horasI = {
					"0:00" : {temperatura: "-"},
					"2:00" : {temperatura: "-"},
					"4:00" : {temperatura: "-"},
					"6:00" : {temperatura: "-"},
					"8:00" : {temperatura: "-"},
					"10:00" : {temperatura: "-"},
					"12:00" : {temperatura: "-"},
					"14:00" : {temperatura: "-"},
					"16:00" : {temperatura: "-"},
					"18:00" : {temperatura: "-"},
					"20:00" : {temperatura: "-"},
					"22:00" : {temperatura: "-"},
				}

				// console.log(fecha);
				Object.keys(registrosPorFecha[fecha]).forEach((camara)=>{
					// console.log("> " + camara);

					fechaI = this.convertirFecha(fecha);
					camaraI = camara;

					Object.keys(registrosPorFecha[fecha][camara]).forEach((horaRegistrada)=>{
						
						


						Object.keys(registrosPorFecha[fecha][camara][horaRegistrada]).forEach((temperatura)=>{
							// console.log(">>> " + registrosPorFecha[fecha][camara][horaRegistrada][temperatura].temperatura + " · " + registrosPorFecha[fecha][camara][horaRegistrada][temperatura].observacion);
							

							horasI[horaRegistrada] = {
								temperatura: registrosPorFecha[fecha][camara][horaRegistrada][temperatura].temperatura,
								observacion: (registrosPorFecha[fecha][camara][horaRegistrada][temperatura].observacion == "Sin observacion") ? null : registrosPorFecha[fecha][camara][horaRegistrada][temperatura].observacion,
								fuera: registrosPorFecha[fecha][camara][horaRegistrada][temperatura].fueraDeRango,
							};
						})
					})
					this.registros.push({
						fecha: fechaI,
						camara: camaraI,
						horas: horasI
					});
					horasI = {
						"0:00" : {temperatura: "-"},
						"2:00" : {temperatura: "-"},
						"4:00" : {temperatura: "-"},
						"6:00" : {temperatura: "-"},
						"8:00" : {temperatura: "-"},
						"10:00" : {temperatura: "-"},
						"12:00" : {temperatura: "-"},
						"14:00" : {temperatura: "-"},
						"16:00" : {temperatura: "-"},
						"18:00" : {temperatura: "-"},
						"20:00" : {temperatura: "-"},
						"22:00" : {temperatura: "-"},
					}
				})
			})
		}).then(()=>{
			// console.log(this.registros);
			this.triggers.load = false;
			this.$nextTick(()=>{
				$('#temperaturas').DataTable({"language": {
					"decimal": ",",
					"thousands": ".",
					"info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
					"infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
					"infoPostFix": "",
					"infoFiltered": "(filtrado de un total de _MAX_ registros)",
					"loadingRecords": "Cargando...",
					"lengthMenu": "Mostrar _MENU_ registros",
					"paginate": {
						"first": "Primero",
						"last": "Último",
						"next": "Siguiente",
						"previous": "Anterior"
					},
					"processing": "Procesando...",
					"search": "Buscar:",
					"searchPlaceholder": "Término de búsqueda",
					"zeroRecords": "No se encontraron resultados",
					"emptyTable": "Ningún dato disponible en esta tabla",
					"aria": {
						"sortAscending":  ": Activar para ordenar la columna de manera ascendente",
						"sortDescending": ": Activar para ordenar la columna de manera descendente"
					},
				},
				"scrollX": false, 
				"search": {regex:true},
				dom: 'Bfrtip',
				responsive: false,
				nowrap: false,
				buttons: [
					'copy',
					'excel',
					'csv',
					'pdf'
				]
			}
	);
			})

			})

    },
}
</script>

<style scoped>

</style>