<template>
  <h4 class="mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>&nbsp;Panel de control</h4>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Sectores</th>
        <th scope="col">Equipos</th>
        <th scope="col">No funcionan</th>
        <th scope="col">Preventivo</th>
        <th scope="col">Recorrida</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(sector) in sectoresFull" :key="sector.id">
        <td>{{sector.nombre}}</td>
        <td><div class="badge badge-light">{{(sector.maquinas != undefined) ? sector.maquinas : '-'}}</div></td>
        <td>
          <div class="badge" :class="(sector.maquinas_defectuosas != undefined) ? 'badge-danger' : 'badge-light'">{{(sector.maquinas_defectuosas != undefined) ? sector.maquinas_defectuosas : '-'}}</div> 
          &nbsp;
          <span class="text-muted" v-if="sector.maquinas_defectuosas!=undefined && sector.maquinas!=undefined">({{preciseRoundeR((sector.maquinas_defectuosas/sector.maquinas)*100, 1)}}%)</span>
        </td>
        <td>0</td>
        <td>0</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {dateFormat,setProp,precise_round} from '@/utils';
export default {
    name:'ListaMaquinas',
    props:{maquinas:Object,sectores:Object},
    data(){
      return {
        sectoresFull:null,
      }
    },
    components:{
      
    },
    mounted(){
      let sectoresAndMaquinas = this.sectores;
      Object.keys(this.maquinas).forEach((i)=>{
        // Se comprueba si el sector con el id dado existe
        if (sectoresAndMaquinas[this.maquinas[i].id_sector] != undefined) {
          // Se comprueba si la propiedad maquinas fue seteada en el sector
          if (sectoresAndMaquinas[this.maquinas[i].id_sector].maquinas == undefined) {
            // Si no fue seteada se crea y se inicializa en 1
            setProp(sectoresAndMaquinas[this.maquinas[i].id_sector], 'maquinas', 1);

          }else {
            // Si la prop maquinas fue seteada se suma 1
            sectoresAndMaquinas[this.maquinas[i].id_sector].maquinas += 1;
          }

          // Se comprueba si la maquina no funciona
          if (!this.maquinas[i].funciona) {
            if (sectoresAndMaquinas[this.maquinas[i].id_sector].maquinas_defectuosas == undefined) {
              setProp(sectoresAndMaquinas[this.maquinas[i].id_sector], 'maquinas_defectuosas', 1);
            }else {
              sectoresAndMaquinas[this.maquinas[i].id_sector].maquinas_defectuosas += 1;
            }
          }

        }
      })

      this.sectoresFull = sectoresAndMaquinas; 
      
    },
    methods:{
      dateFormateR(date){
        return dateFormat(date);
      },
      preciseRoundeR(num,decimales){
        return precise_round(num,decimales);
      }
    }
}
</script>

<style scoped>
  /* .text-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  :deep(.popper) {
    background: #2f2f2f;
    padding: 10px;
    border-radius: 8px;
    color: #fff;
}

  :deep(.popper #arrow::before) {
    background: #2f2f2f;
  }

  :deep(.popper:hover),
  :deep(.popper:hover > #arrow::before) {
    background: #2f2f2f;
  } */
</style>