<template>
    <div v-if="trigger.save">
        <div class="w-50 py-5 mx-auto text-center">
            <div class="d-flex justify-content-center">
                <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <h4 class="mt-3">Modificando el equipo</h4>
            <p>Sea paciente, no demora mucho</p>
            <!-- Spinner de carga -->
        </div>
    </div>
    <div v-if="trigger.show">
        <div class="w-75 py-5 mx-auto text-center text-success">
            <h4>&check; Equipo modificado correctamente</h4>
            <p class="text-dark">Serás redirigido en 1 segundos</p>
        </div>
    </div>
    <form v-if="trigger.write">
        <h4 class="mb-3">Modificando un equipo</h4>
        <div class="form-row mb-2">
            <div class="col">
                <label class="form-label">Nombre</label>
                <input type="text" class="form-control" v-model="maquina.nombre" @input="error=''" placeholder="Camaras de desc..."/>
                <span class="text-danger" v-if="error.nombre.length">&otimes; {{error.nombre}}</span>
            </div>
            <div class="col">
                <label class="form-label">Sector</label>
                <select v-model="maquina.id_sector" class="form-control">
                    <option value="" disabled>Selecciona un sector</option>
                    <option :value="sector.id" v-for="sector in sectores" :key="sector.id">{{sector.nombre}}</option>
                </select>
                <span class="text-danger" v-if="error.id_sector.length">&otimes; {{error.id_sector}}</span>
            </div>
        </div>

        <div class="row">
            <div class="col text-right">
                <button class="btn btn-lg btn-success" @click.prevent="editar()">Editar</button>
            </div>
        </div>
    </form>	
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

import { url } from "../../../../.env";
import { getDataFrom } from "@/utils";


export default {   
	name: 'CrearSector',
    props:{sectores:Object,maquinaOriginal:Object},
    data() {
        return {
			maquina: {
                nombre: null,
                timestamp: new Date().getTime(),
                id_sector: '',
                funciona: true,
                recorrida: false,
                preventivo: false,
            },
			error:{
                nombre: '',
                id_sector: '',
            },
            ultimo_id: null,
            trigger: {
                write: true,
                save: false,
                show: false,
            }
        }
    },
    methods: {
		editar(){
            let valid = true;
            Object.keys(this.maquina).forEach((prop)=>{
                if(this.error[prop] != undefined) this.error[prop] = '';
                if(this.maquina[prop] == null || this.maquina[prop] === ''){
                    this.error[prop] = "Campo obligatorio, no puede estar vacio";
                    valid = false;
                }
            })

            if(!valid) return null;

	

            getDataFrom('id_maquinas',url).then((id)=>{
                if(id != null){
                    this.maquina.id = id;

                    this.trigger.write = false;
                    this.trigger.save = true;
                    
                    firebase.database().ref(`/maquinas/${this.maquinaOriginal.fireId}`)
                    .update(this.maquina)
                    .then(()=>{
                        this.trigger.save = false;
                        this.trigger.show = true;
                        setTimeout(() => {
                            this.$emit('cerrar',true);
                        }, 2000);
                    })
                    .catch((err)=>{
                        console.warn(err);
                        alert("Se produjo un error al crear el maquina, actualice la página y reintente.")
                    })

                }else{
                    // TODO Manejar el error creando un ir aleatorio
                    console.warn("No se encontro un ultimo ID de maquinas");
                    alert("Se produjo un erro al crear la maquina, actualice la página y reintente.")
                }
            })
            .catch((err)=>{
                console.warn(err);
                alert("Se produjo un erro al crear la maquina, actualice la página y reintente.")
            })
		},
		
    },
	mounted() {
        this.maquina = this.maquinaOriginal.maquina;
    },
}
</script>

<style scoped>
.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
</style>