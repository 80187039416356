<template>
    <div v-if="trigger.save">
        <div class="w-50 py-5 mx-auto text-center">
            <div class="d-flex justify-content-center">
                <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <h4 class="mt-3">Modificando el estado de la maquina</h4>
            <p>Sea paciente, no demora mucho</p>
            <!-- Spinner de carga -->
        </div>
    </div>
    <div v-if="trigger.show">
        <div class="w-75 py-5 mx-auto text-center text-success">
            <h4>&check; Estado actualizado</h4>
            <p class="text-dark">Redirección en 1 segundos</p>
        </div>
    </div>
    <form v-if="trigger.write">
        <h4 class="mb-3">Maquina: {{maquinaOriginal.maquina.nombre}}</h4>
        <div class="form-row">
            <div class="col-12 mb-2">
                <label class="form-label">Seleccione el estado</label>
                <select v-model="maquina.funciona" class="form-control">
                    <option :value="true">Funcionando</option>
                    <option :value="false">No funciona</option>
                </select>
            </div>
            <div class="col-12 mb-2">
                <label class="form-label">Observación del estado</label>
                <textarea rows="3" class="form-control w-100" v-model="maquina.evento_descripcion"></textarea>
                <div class="text-danger" v-if="error.evento_descripcion.length">&otimes; {{error.evento_descripcion}}</div>
            </div>
            <div class="col-12 text-right mt-3">
                <button class="btn btn-light mr-2" @click="$emit('cerrar', false)">Cancelar</button>
                <button class="btn btn-warning" @click="actualizar()">Registrar estado</button>
            </div>
        </div>
    </form>	
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

// import { url } from "../../../../.env";
// import { getDataFrom } from "@/utils";


export default {   
	name: 'CrearMaquina',
    props:{maquinaOriginal:Object},
    data() {
        return {
			maquina: {
                funciona: null,
                evento_descripcion: null,
            },
			error:{
                evento_descripcion:'',
            },
            trigger: {
                write: true,
                save: false,
                show: false,
            }
        }
    },
    methods: {
		actualizar(){
            if (this.maquina.funciona == false) {
                if(this.maquina.evento_descripcion == null || this.maquina.evento_descripcion  == ''){
                    this.error.evento_descripcion = 'Ingrese un detalle sobre el evento';
                    return null;
                }
                this.maquina.evento_timestamp = new Date().getTime()
            }else {
                this.maquina.evento_descripcion = null;
                this.maquina.evento_timestamp = new Date().getTime()
            }



            this.trigger.write = false;
            this.trigger.save = true;


            firebase.database().ref(`/maquinas/${this.maquinaOriginal.fireId}`)
            .update(this.maquina)
            .then(()=>{
                this.trigger.save = false;
                this.trigger.show = true;
                setTimeout(() => {
                    this.$emit('cerrar',true);
                }, 1000);
            })
            .catch((err)=>{
                console.warn(err);
                alert("Se produjo un error al crear el maquina, actualice la página y reintente.")
            })
		},
		
    },
	mounted() {
        this.maquina = this.maquinaOriginal.maquina;
    },
}
</script>

<style scoped>
</style>