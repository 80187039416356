<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="d-flex w-100 align-items-center ">
                            <div class="col-10">
                                <h4>
                                    <span class="badge badge-info">{{registro.horas[horaSeleccionada].observacion}}</span>
                                en <span class="badge badge-info">{{ registro.camara }}</span> a las <span class="badge badge-info">{{horaSeleccionada}}</span>
                                </h4>
                            </div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold p-3 px-4" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                        <!-- <div class="modal-body" v-if="!success">
                            {{registro.horas[horaSeleccionada].observacion}}
                        </div> -->
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
// import firebase from "firebase/app";
// import "firebase/database";

export default {
    name: "ModalObservacion",
    props: ['registro','horaSeleccionada'],
    data(){
        return {
            // Controla las actualizaciones del padre.
            // seReasigno: false,
        }
    },
    mounted(){
        
    },
    methods: {
        
        close(){
            this.$emit('cerrar-modal-observacion',this.seReasigno);
        },
        
    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 80%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>