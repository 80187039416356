<template>
    <div v-if="trigger.save">
        <div class="w-50 py-5 mx-auto text-center">
            <div class="d-flex justify-content-center">
                <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <h4 class="mt-3">Añadiendo el sector</h4>
            <p>Sea paciente, no demora mucho</p>
            <!-- Spinner de carga -->
        </div>
    </div>
    <div v-if="trigger.show">
        <div class="w-75 py-5 mx-auto text-center text-success">
            <h4>&check; Sector añadido correctamente</h4>
            <p class="text-dark">Serás redirigido en 2 segundos</p>
        </div>
    </div>
    <form v-if="trigger.write">
        <h4 class="mb-3">Agregar un nuevo sector</h4>
        <label class="form-label">Nombre</label>
        <div class="d-flex">
            <div class="w-75 pr-3">
                <input type="text" class="form-control" v-model="sector.nombre" @input="error=''" placeholder="Camaras de desc..."/>
                <span class="text-danger" v-if="error.length">&otimes; {{error}}</span>
            </div>
            <div class="w-25">
                <button class="btn btn-lg btn-success w-100" @click.prevent="agregarObservacion()">Agregar</button>
            </div>
        </div>
    </form>	
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

import { url } from "../../../../.env";
import { getDataFrom } from "@/utils";


export default {   
	name: 'CrearSector',
    data() {
        return {
			sector: {
                nombre: null,
                id: null,
                timestamp: new Date().getTime()
            },
			
			error:'',
            ultimo_id: null,
            trigger: {
                write: true,
                save: false,
                show: false,
            }
        }
    },
    methods: {
		agregarObservacion(){
			if(this.sector.nombre == null || this.sector.nombre == 0){
				this.error = 'Ingrese un nombre para el sector';
				return null;
			}

            getDataFrom('id_sectores',url).then((id)=>{
                if(id != null){
                    this.sector.id = id;

                    this.trigger.write = false;
                    this.trigger.save = true;
                    firebase.database().ref('/sectores')
                    .push(this.sector)
                    .then(()=>{
                        firebase.database().ref('/').update({id_sectores:id+1}).then(()=>{
                            this.trigger.save = false;
                            this.trigger.show = true;
                            setTimeout(() => {
                                this.$emit('cerrar',true);
                            }, 2000);
                        })
                    })
                    .catch((err)=>{
                        console.warn(err);
                        alert("Se produjo un error al crear el sector, actualice la página y reintente.")
                    })

                }else{
                    // TODO Manejar el error creando un ir aleatorio
                    console.warn("No se encontro un ultimo ID de sectores");
                    alert("Se produjo un erro al crear el sector, actualice la página y reintente.")
                }
            })
            .catch((err)=>{
                console.warn(err);
                alert("Se produjo un erro al crear el sector, actualice la página y reintente.")
            })
		},
		
    },
	mounted() {
    },
}
</script>

<style scoped>
.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
</style>