import { createWebHashHistory, createRouter } from "vue-router";

import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";

import Temperaturas from '@/views/temperaturas/Temperaturas.vue';
import Registros from '@/views/registros/Registros.vue';
import Maquinas from '@/views/Maquinas.vue';
import Sectores from '@/views/Sectores.vue';
import Articulos from '@/views/Articulos.vue';
import Marcas from '@/views/Marcas.vue';
import Rubros from '@/views/Rubros.vue';
import Busqueda from '@/components/Articulo/Buscador.vue';

const routes = [
 {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
   {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/temperaturas",
    name: "Temperaturas",
    component: Temperaturas,
  },
  {
    path: "/registros",
    name: "Registros",
    component: Registros,
  },
  {
    path: "/maquinas",
    name: "Maquinas",
    component: Maquinas,
  },
  {
    path: "/maquinas/crear",
    name: "Maquinas",
    component: Maquinas,
  },
  {
    path: "/sectores",
    name: "Sectores",
    component: Sectores,
  },
  {
    path: "/articulos",
    name: "Articulos",
    component: Articulos,
  },
  {
    path: "/marcas",
    name: "Marcas",
    component: Marcas,
  },
  {
    path: "/rubros",
    name: "Rubros",
    component: Rubros,
  },
  {
    path: "/busqueda",
    name: "Busqueda",
    component: Busqueda,
  },
];



const router = createRouter({
  history: createWebHashHistory(),
  routes,
  mode: 'hash'
});

router.beforeEach((to, from, next) => {
  // if(to.name == 'Metricas') next()
  next()
})


export default router;