<template>
  <h4 class="mb-3">Lista de maquinas existentes</h4>
  
  <div class="table-responsive">
    <table id="tabla" class="table">
      <thead>
        <tr>
          <th >ID</th>
          <th>Sector</th>
          <th>Equipo</th>
          <th>Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(maquina,i) in maquinas" :key="maquina.id">
          <td><div class="badge badge-light">{{maquina.id}}</div></td>
          <td><div class="badge badge-primary">{{(sectores[maquina.id_sector]!=undefined) ? sectores[maquina.id_sector].nombre : '-'}}</div></td>
          <td>{{maquina.nombre}}</td>
          <td>
            <Popper arrow placement="top" :disabled="maquina.evento_descripcion==undefined">
              <div class="badge pointer" :class="(maquina.funciona)?'badge-success':'badge-warning'">{{(maquina.funciona)?'Funcionando':'No funciona'}}</div>
              <template #content="{ close }">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <div class="text-mutted" style="font-size:11px">
                      &olarr; {{dateFormateR(maquina.evento_timestamp)}}
                    </div>
                    <div class="text-wrap" style="max-width: 200px;">{{maquina.evento_descripcion}}</div>
                  </div>
                  <Button @click="close" class="border-0 bg-transparent text-white ml-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </Button>
                </div>
              </template>
            </Popper>


            <!-- <Popper :content="maquina.evento_descripcion" placement="top">
              <div class="badge" :class="(maquina.funciona)?'badge-success':'badge-warning'">{{(maquina.funciona)?'Funcionando':'No funciona'}}</div>
              <template #content="{ close }">
                <Button @click="close">Close</Button>
              </template>
            </Popper> -->
          </td>
          <td>
              <button class="btn btn-primary mr-2" @click.prevent="$emit('controlar',{fireId:i,maquina:maquina})"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg> Controlar</button>
              <button class="btn btn-primary" @click.prevent="$emit('editar',{fireId:i,maquina:maquina})">Editar</button>
          </td>
          
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery';
import {dateFormat} from '@/utils';
import {tableConfig} from '../../../.env';
 import Popper from "vue3-popper";
export default {
    name:'ListaMaquinas',
    props:{maquinas:Object,sectores:Object},
    components:{
      Popper
    },
    mounted(){
      $('#tabla').dataTable(tableConfig)
    },
    methods:{
      dateFormateR(date){
        return dateFormat(date);
      }
    }
}
</script>

<style scoped>
  .text-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  :deep(.popper) {
    background: #2f2f2f;
    padding: 10px;
    border-radius: 8px;
    color: #fff;
}

  :deep(.popper #arrow::before) {
    background: #2f2f2f;
  }

  :deep(.popper:hover),
  :deep(.popper:hover > #arrow::before) {
    background: #2f2f2f;
  }
</style>