<template>
	<div class="page-content">
		<!-- Breadcrumb -->
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<!-- Page title -->
		<!-- <div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				</div>  
			</div>
			</div>
		</div>  -->
		<!-- Template content  -->
		<div class="row">
			<div class="col-12">
				<div class="alert alert-success" v-if="triggers.success">
					Temperatura registrada correctamente
				</div>
			</div>
			<div class="col-md-12 grid-margin stretch-card">
				
			<div class="card">
				<div class="card-body">
					<h6 class="card-title">{{descripcion}}</h6>
					<form>
						<!-- Primer stage: fecha y camara -->
						<div class="row">
							<div class="col-12 col-md-6 mb-3">
								<label for="select-fecha" class="form-label">Fecha</label> 
								<input type="date" class="form-control" id="select-fecha" v-model='registro.fecha' @change="comprobarFecha(registro.fecha)"/>
								<span class="text-danger" v-if="errors.fecha">· {{errors.fechaMensaje}}</span>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<label for="select-camara" class="form-label">Camara</label> 
								<select id="select-camara" name="select-camara" class="custom-select" v-model="camaraSeleccionada" @change="registrarCamaraObtenerCentral()">
									<option 
									v-for="(camara,i) in camaras" 
									:key="i" 
									:value="{
										min:camara.min,
										max:camara.max,
										codigo:camara.central,
										nombre:camara.nombre,
									}"
									>{{camara.nombre}}</option>
								</select>
								<span class="text-danger" v-if="errors.camara">· La camara no puede estar vacia</span>
							</div>
							<div class="col-12" v-if="triggers.vistaCentralyRangos">
								<div class="row">
									<div class="col-12 col-md-4">
										<div class="alert alert-warning">
											<label class="form-label">Rango de temperaturas</label> <br>
											MIN: <span class="badge badge-warning">{{camaraSeleccionada.min}}</span> MAX: <span class="badge badge-warning">{{camaraSeleccionada.max}}</span>
										</div>
									</div>
									<div class="col-12 col-md-8" v-if="!sinCentral">
										<div class="alert alert-info">
											<label class="form-label">Central de frio: <span class="badge badge-info">{{centralSeleccionada.zona}}</span></label> <br>
											Código: <span class="badge badge-info">{{camaraSeleccionada.codigo}}</span> <span class="badge badge-info">{{centralSeleccionada.sistema}}</span> <span class="badge badge-info">Media {{centralSeleccionada.media}} hp</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Segundo stage: temperatura, hora y observacion -->
						<div class="row">
							<div class="col-12 col-md-6 mb-3">
								<label for="select-horario" class="form-label">Horario de registro</label> 
								<select id="select-horario" class="custom-select" v-model='registro.horario'>
									<option 
									v-for="(horario,i) in horarios" 
									:key="i"
									:value="horario"
									>{{horario}}</option>
								</select>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<label for="input-temperatura" class="form-label">Temperatura</label> 
								<input type="number" class="form-control" id="input-temperatura" v-model="registro.temperatura" placeholder="10.0">
								<span class="text-danger" v-if="errors.temperatura" @change="errors.temperatura = false">· La temperatura no puede estar vacia</span>
							</div>
							<div class="col-12">
								<label for="select-observacion" class="form-label">Observacion</label> 
								<select id="select-observacion" class="custom-select" v-model='registro.observacion'>
									<option value="Sin observacion" selected>Sin observacion</option>
									<option 
										v-for="(observacion,i) in observaciones" 
										:key="i" 
										:value="observacion">{{observacion}}</option>
								</select>
							</div>
							<div class="col-12 text-right">
								<button class="btn btn-lg btn-success mt-3" 
								@click.prevent="registrarTemperatura()"
								>Registrar temperatura</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-body">
						<nueva-observacion></nueva-observacion>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
import { apis } from '../../../.env.js';
import NuevaObservacion from '../observaciones/NuevaObservacion';

export default {   
	name: 'Temperaturas',
	components: {NuevaObservacion},
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Temperaturas',
            descripcion: 'Resgistra las ultimas temperaturas',
			centrales: null,
			camaras: null,
			registro: {
				fecha: new Date().toISOString().slice(0,10),
				horario: "0:00",
				camara: "Expedicion",
				temperatura: null,
				central: null,
				camara: null,
				observacion: "Sin observacion",
			},
            triggers: {
				vistaCentralyRangos: false,
				success: false,
				// temperatura: false,
			},
			horarios: [
				"0:00",
				"2:00",
				"4:00",
				"6:00",
				"8:00",
				"10:00",
				"12:00",
				"14:00",
				"16:00",
				"18:00",
				"20:00",
				"22:00"
			],
			observaciones: [
				'Descongelamiento',
				'Falla mecánica',
				'Alimento caliente',
				'Aviso a mantenimiento',
				'Puerta abierta',
				'Ingreso de producto'
			],
			camaraSeleccionada: [],
			centralSeleccionada:{
				sistema:null,
				media:null,
				zona:null
			},
			sinCentral: false,
			errors: {
				fecha: false,
				fechaMensaje: null,
				temperatura: false,
				camara:false,
			}
        }
    },
    methods: {
		obtenerFecha(){
			new Date().toISOString().slice(0,10);
		},
		registrarCamaraObtenerCentral(){
			this.errors.camara = false;
			this.triggers.vistaCentralyRangos = true;
			if (this.camaraSeleccionada.codigo == null || this.camaraSeleccionada.codigo == undefined) {
				this.registro.camara = this.camaraSeleccionada.nombre;
				this.sinCentral = true;
				return null
			}
			this.sinCentral = false;
			const central = this.camaraSeleccionada.codigo.toLowerCase();
			this.centralSeleccionada.zona = this.centrales[central].zona;
			this.centralSeleccionada.sistema = this.centrales[central].sistema;
			this.centralSeleccionada.media = this.centrales[central].media;

			this.registro.camara = this.camaraSeleccionada.nombre;
			this.registro.central = central;
		},
		registrarTemperatura(){
			this.triggers.success = false;
			this.errors.temperatura = false;
			this.errors.camara = false;

			let errors = false;
			
			if (!this.comprobarFecha()) errors = true;
			
			if (this.registro.temperatura==null || this.registro.temperatura.length == 0) {
				this.errors.temperatura = true;
				errors = true
			}

			if(this.registro.camara == null){
				this.errors.camara = true;
				errors = true
			}

			if (errors) return null

			

			firebase.database().ref('/registro_temperaturas/' + this.registro.fecha + "/" + this.registro.camara +"/"+ this.registro.horario).push({
					temperatura: this.registro.temperatura,
					observacion: this.registro.observacion,
					fueraDeRango: this.estaFueraDeRango(this.registro.temperatura,this.camaraSeleccionada.min,this.camaraSeleccionada.max),
				}
				// fecha: this.registro.fecha,
				// horario: this.registro.horario,
				// camara: this.registro.camara,
				// temperatura: this.registro.temperatura,
				// central: this.registro.central,
				// camara: this.registro.camara,
				// observacion: this.registro.observacion,
			).then(()=>{
				console.log("Registro creado");
				this.triggers.success = true;
				this.triggers.vistaCentralyRangos = false;
				this.registro.fecha = new Date().toISOString().slice(0,10);
				this.registro.horario = "0:00";
				this.registro.camara = null;
				this.registro.temperatura = null;
				this.registro.central = null;
				this.registro.camara = null;
				this.registro.observacion = "Sin observacion";

				this.$nextTick();
			}).catch(error=>{
				if (error) {
					alert("Se produjo un error")
					console.log(error);
				}
			})

			
		},
		estaFueraDeRango(valor,min,max){
			return (valor<=min || valor>=max)
		},
		comprobarFecha(){
			console.log("estoy comprobando la fecha..");

			if (this.registro.fecha.length == 0) {
				this.errors.fechaMensaje = "La fecha no pude estar vacia";
				this.errors.fecha = true;
				return false;
			}

			this.errors.fecha = false;
			var fechaISO = this.registro.fecha.split('-');
			// Please pay attention to the month (parts[1]); JavaScript counts months from 0:
			// January - 0, February - 1, etc.
			var fechaEntera = new Date(fechaISO[0], fechaISO[1] - 1, fechaISO[2]); 

			if (fechaEntera > new Date()) {
				this.errors.fechaMensaje = "La fecha no pude estar en el futuro";
				this.errors.fecha = true;
				return false;
			}

			return true;
			
		}
    },
	async mounted() {
		const reqCentrales = axios.get(apis.centrales_frio);
		const reqCamaras = axios.get(apis.camaras);

		await axios.all([reqCentrales, reqCamaras]).then(axios.spread((centrales,camaras) => {
			this.centrales = centrales.data;
			this.camaras = camaras.data;
		}));
    },
}
</script>

<style scoped>
.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.textarea {
	height: auto;
}
</style>