<template>
    <div class="page-content">
      <!-- Breadcrumb -->
      <nav class="page-breadcrumb" id="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">{{breadcrumbA}}</li>
          <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
        </ol>
      </nav>
      <!-- Card view title and description -->
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h6 class="card-title">{{ breadcrumbB }}</h6>
              <div class="d-flex align-items-center justify-content-between">
                <p class="card-description w-50 p-0 m-0">{{ descripcion }}</p>
                
                <div class="">
                  <button
                    v-on:click="refrescarTriggers('listar')"
                    type="button"
                    class="btn btn-light"
                    v-if="trigger.crear"
                  >Cancelar la creación</button>
                  <button
                    v-on:click="refrescarTriggers('crear')"
                    type="button"
                    class="btn  btn-success ml-1"
                    v-if="!trigger.crear"
                  >Añadir rubro</button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12 mb-3" v-if="trigger.crear">
          <div class="card">
            <div class="card-body">
              <crear-rubro :rubros="rubros" @cerrar="cerrar($event)"></crear-rubro>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3" v-if="trigger.editar">
          <div class="card">
            <div class="card-body">
              <editar-rubro :rubroEdicion="seleccion" @cerrar="cerrar($event)"></editar-rubro>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3" v-if="trigger.listar">
          <div class="card">
            <div class="card-body">
              <lista-rubros :rubros="rubros" @cerrar="cerrar($event)" @administrar-sectores="realizarAccion($event,'administrarSectores')" @editar="realizarAccion($event,'editar')"></lista-rubros>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3" v-if="trigger.administrarSectores">
          <div class="card">
            <div class="card-body">
              <administrar-sectores :rubro="seleccion" @cerrar="cerrar($event)"></administrar-sectores>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- <modal-prompt :type="'warning'" v-if="trigger.registrarEstado">
      <template v-slot:title><svg xmlns="http://www.w3.org/2000/svg" style="position: relative; bottom: 2px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>&nbsp;Registrar estado de máquina</template>
      <template v-slot:body>
        <actualizar-estado :maquinaOriginal="seleccion" @cerrar="cerrar($event)"></actualizar-estado>
      </template>
    </modal-prompt> -->
</template>
<script>
import CrearRubro from '@/components/Articulo/forms/CrearRubro.vue';
import EditarRubro from '@/components/Articulo/forms/EditarRubro.vue';
import AdministrarSectores from '@/components/Articulo/forms/AdministrarSectores.vue';
// Lista
import ListaRubros from '@/components/Articulo/ListaRubros.vue';

// import ModalPrompt from '@/components/ModalPrompt.vue';
import {getDataFrom} from '@/utils';
import {url} from '../../.env';

export default {
  name: 'Rubros',
  components: {
    CrearRubro,
    EditarRubro,
    ListaRubros,
    AdministrarSectores
  },
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Rubros',
      descripcion:'Esta sección permite añadir rubros, listar y editarlos. Los rubros se utilizan en la gestión de artículos.',
      rubros:null,
      seleccion: null,
      trigger: {
        crear: false,
        editar: false,
        listar: false,
        administrarSectores:false
      },
  
  }),
  methods: {
    refrescarTriggers(activo) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(activo!=undefined) this.trigger[activo] = true
    },
    cerrar(actualizar) {
        this.refrescarTriggers('listar');
        if (actualizar) {
            // Obtener devuelve como promesa truo o false en caso de devolver datos.
            this.trigger.listar = false;
            // TODO Mejorar la logica, se deberia crear la funcion obtener para llamar.
            
            getDataFrom('articulos_rubro',url).then((response)=>{
              if(response!=null){
                this.rubros = response;
                this.trigger.listar = true;
              }else {
                alert("Ocurrio un error, actualice la página");
                console.warn("No se obtuvo ningún resultado a través de la api maquinas")
              }
            })
            .catch((err)=>{
              alert("Ocurrio un error, actualice la página");
              console.warn(err)
            })
        }
    },
    // TODO generalizar metodo junto a refrescar
    realizarAccion(seleccion,accion){
      this.seleccion = seleccion;
      this.refrescarTriggers(accion);
    },

    
  },
  mounted(){
    getDataFrom('articulos_rubro',url).then((response)=>{
      if(response!=null){
        this.rubros = response;
        this.trigger.listar = true;
      }else {
        alert("Ocurrio un error, actualice la página");
        console.warn("No se obtuvo ningún resultado a través de la api maquinas")
      }
    })
    .catch((err)=>{
      alert("Ocurrio un error, actualice la página");
      console.warn(err)
    })
    // getDataFrom('articulos',url).then((response) => {
    //   this.articulos = response;
    //   this.trigger.crearArticulo = true;
    // })


    // getDataFrom('maquinas',url).then((response)=>{
    //   if(response!=null){
    //     this.maquinas = response; 

    //     getDataFrom('sectores',url).then((data)=>{
    //         if(data != null){
    //             this.sectores = {};
    //             Object.keys(data).forEach((e)=>{
    //               console.log(data[e]);
    //               setProp(this.sectores, data[e].id, data[e]);
    //             })
    //             this.trigger.panelControl  = true;
    //             this.trigger.listar = true;
    //         }else{
    //             // TODO Manejar el error creando un ir aleatorio
    //             console.warn("No se encontraron sectores");
    //             alert("Se produjo un erro al crear la maquina, actualice la página y reintente.")
    //         }
    //     })
    //     .catch((err)=>{
    //         console.warn(err);
    //         alert("Se produjo un erro al crear la maquina, actualice la página y reintente.")
    //     })
    //   }else {
    //     this.trigger.listar = true;
    //     // alert("Ocurrio un error, actualice la página");
    //     console.warn("No se obtuvo ningún resultado a través de la api maquinas")
    //   }
    // })
    // .catch((err)=>{
      
    //   alert("Ocurrio un error, actualice la página");
    //   console.warn(err)
    // })



    // this.obtener('maquinas').then((result)=>{
    //   this.trigger.listar = true
    // })



  }
};

</script>