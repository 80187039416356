<template>
    <div v-if="trigger.save">
        <div class="w-50 py-5 mx-auto text-center">
            <div class="d-flex justify-content-center">
                <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <h4 class="mt-3">Modificando el articulo</h4>
            <p>Sea paciente, no demora mucho</p>
            <!-- Spinner de carga -->
        </div>
    </div>
    <div v-if="trigger.show">
        <div class="w-75 py-5 mx-auto text-center text-success">
            <h4>&check; Articulo modificado correctamente</h4>
            <p class="text-dark">Serás redirigido en 1 segundos</p>
        </div>
    </div>
    <form v-if="trigger.write">
        <h4 class="mb-3">Moificación de un articulo</h4>
        <div class="alert alert-info">
            Es posible seleccionar únicamente archivos en formato <b>PDF</b> y en formato de imágenes como <b>JPG</b> o <b>PNG</b>. <br><br> Al cargar archivos en formato <b>PDF</b>, estos se dividen en varias imagenes hoja por hoja del documento. De esta forma es posible eliminar hojas innecesarias o agregar nuevas y todasestas hojas finalmente se almacenan en formato de imágen <b>PNG</b>.
        </div>
        <div class="form-row">
            <div class="col-12 mb-3">
                <div class="form-label">Ficha técnica</div>
                <input-file :titulo="'Agregar documentación'" :key="inputFileUpdateKey1" @uploading="handleStateFileUploading($event)" :multiple='true' :error="{estado: false,mensaje:''}" :update="checkAndGetDocuments('ficha_tecnica')" @seleccion="asignarDocumentos('ficha_tecnica',$event)"></input-file>
                <!-- <div class="text-danger mt-1" v-if="error.articulo.documentacion.length">&otimes; {{error.articulo.email}}</div> -->
            </div>
            <div class="col-12 mb-3">
                <div class="form-label">Hoja de seguridad</div>
                <input-file :titulo="'Agregar documentación'" :key="inputFileUpdateKey2" @uploading="handleStateFileUploading($event)" :multiple='true' :error="{estado: false,mensaje:''}" :update="checkAndGetDocuments('hoja_seguridad')" @seleccion="asignarDocumentos('hoja_seguridad',$event)"></input-file>
                <!-- <div class="text-danger mt-1" v-if="error.articulo.documentacion.length">&otimes; {{error.articulo.email}}</div> -->
            </div>
            <div class="col-12 mb-3">
                <div class="form-label">Aprobación</div>
                <input-file :titulo="'Agregar documentación'" :key="inputFileUpdateKey3" @uploading="handleStateFileUploading($event)" :multiple='true' :error="{estado: false,mensaje:''}" :update="checkAndGetDocuments('aprobacion')" @seleccion="asignarDocumentos('aprobacion',$event)"></input-file>
                <!-- <div class="text-danger mt-1" v-if="error.articulo.documentacion.length">&otimes; {{error.articulo.email}}</div> -->
            </div>
            <div class="col-12 mb-3">
                <div class="form-label">Varios</div>
                <input-file :titulo="'Agregar documentación'" :key="inputFileUpdateKey4" @uploading="handleStateFileUploading($event)" :multiple='true' :error="{estado: false,mensaje:''}" :update="checkAndGetDocuments('varios')" @seleccion="asignarDocumentos('varios',$event)"></input-file>
                <!-- <div class="text-danger mt-1" v-if="error.articulo.documentacion.length">&otimes; {{error.articulo.email}}</div> -->
            </div>
            <div class="col-12 col-md-6 mb-3">
                <div class="form-label">Nombre</div>
                <input type="text" class="form-control" v-model="articulo.nombre">
                <div class="text-danger mt-1" v-if="error.articulo.nombre.length">&otimes; {{error.articulo.nombre}}</div>
            </div>
            <div class="col-12 col-md-6 mb-3">
                <div class="form-label">Fecha de vencimiento</div>
                <date-picker v-model="articulo.vencimiento">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                        />
                    </template>
                </date-picker>
                <div class="text-danger mt-1" v-if="error.articulo.vencimiento.length">&otimes; {{error.articulo.vencimiento}}</div>
            </div>
            <div class="col-12 col-md mb-3">
                <div class="form-label">Rubro</div>
                <select v-model="articulo.rubro" class="form-select">
                    <option :value="null" disabled>Seleccione un rubro</option>
                    <option :value="i" v-for="(rubro,i) in rubros" :key="i">{{rubro.nombre}}</option>
                </select>
                <div class="text-danger mt-1" v-if="error.articulo.rubro.length">&otimes; {{error.articulo.rubro}}</div>
            </div>
            <div class="col-12 col-md mb-3" v-if="articulo.rubro">
                <div class="form-label">Sector</div>
                <select v-model="articulo.rubro_sector" class="form-select">
                    <option :value="null" disabled>Seleccione un sector</option>
                    <option :value="i" v-for="(sector,i) in rubros[articulo.rubro].sectores" :key="i">{{sector.nombre}}</option>
                </select>
                <div class="text-danger mt-1" v-if="error.articulo.rubro_sector.length">&otimes; {{error.articulo.rubro_sector}}</div>
            </div>
            <div class="col-12 col-md mb-3">
                <div class="form-label">Marca</div>
                <select v-model="articulo.marca_articulo" class="form-select">
                    <option :value="null" disabled>Seleccione una marca</option>
                    <option :value="i" v-for="(marca,i) in marcas" :key="i">{{marca.nombre}}</option>
                </select>
                <div class="text-danger mt-1" v-if="error.articulo.marca_articulo.length">&otimes; {{error.articulo.marca_articulo}}</div>
            </div>
            <div class="col-12  mb-3">
                <div class="form-label">Observación</div>
                <textarea v-model="articulo.observaciones" class="form-control w-100"></textarea>
                <!-- <textarea row="4" class="form-control w-100" v-model="articulo.observaciones"></textarea> -->
                <!-- <div class="text-danger mt-1" v-if="error.articulo.observaciones.length">&otimes; {{error.articulo.observaciones}}</div> -->
            </div>
            <div class="col-12 d-flex mt-4 justify-content-end">
                <button class="btn btn-light mr-2" @click.prevent="$emit('cerrar',false)">Cancelar</button>
                <button class="btn btn-success d-flex align-items-center" disabled v-if="trigger.uploadingImages">
                    <div class="spinner-border text-light mr-2" role="status" style="width: 1rem; height: 1rem; border-width: .2rem;">
                        <span class="sr-only"></span>
                    </div>
                    Cargando los archivos
                </button>
                <button class="btn btn-success" @click.prevent="crear()" v-else>Modificar</button>
            </div>
        </div>
    </form>	
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

import { isEmpty,setProp } from "@/utils";

import {  DatePicker } from 'v-calendar';
require('@popperjs/core');


import InputFile from '@/components/InputFile';

export default {   
	name: 'CrearArticulo',
    components:{InputFile,DatePicker},
    props:{marcas:Object,rubros:Object,articuloEdicion:Object},
    data() {
        return {
			articulo: {
                nombre: null,
                vencimiento:new Date(),
                observaciones:null,
                rubro: null,
                rubro_sector: null,
                marca_articulo: null,
                documentacion:{
                    aprobacion:{
                        imagenes:[],
                    },
                    ficha_tecnica:{
                        imagenes:[],
                    },
                    hoja_seguridad:{
                        imagenes:[],
                    },
                    varios:{
                        imagenes:[],
                    }
                },
                timestamp: new Date().getTime()
            },
			error:{
                articulo: {
                    nombre: '',
                    vencimiento:'',
                    rubro: '',
                    rubro_sector:'',
                    marca_articulo: '',
                },
            },
            requiredInputs: ['nombre','vencimiento','rubro','rubro_sector','marca_articulo'],
            trigger: {
                write: true,
                save: false,
                show: false,
                uploadingImages:false,
            },
            inputFileUpdateKey1:0,
            inputFileUpdateKey2:0,
            inputFileUpdateKey3:0,
            inputFileUpdateKey4:0,
        }
    },
    methods: {
        handleStateFileUploading(state){
            this.trigger.uploadingImages = state
        },
        asignarDocumentos(doc,images){
            if (this.articulo.documentacion==undefined) {
                setProp(this.articulo,'documentacion',{});
            }
            if (this.articulo.documentacion[doc]==undefined) {
                setProp(this.articulo.documentacion,doc,{imagenes:[]});
            }
            this.articulo.documentacion[doc].imagenes = images;
            this.trigger.uploadingImages = false;
        },
		crear(){
			
            if(!this.valido()) return null;

            this.trigger.write = false;
            this.trigger.save = true;

            if(this.articulo.vencimiento instanceof Date){
                this.articulo.vencimiento = this.articulo.vencimiento.getTime();
            }

            delete this.articulo.id;
            firebase.database().ref(`/articulos/${this.articuloEdicion.id}`)
            .update(this.articulo)
            .then(()=>{
                this.trigger.save = false;
                this.trigger.show = true;
                setTimeout(() => {
                    this.$emit('cerrar',true);
                }, 2000);
            })
            .catch((err)=>{
                console.warn(err);
                alert("Se produjo un error al crear el articulo, pruebe actualizar la página y reintentar.")
            })
		},
        valido(){
            let valido = true;
            this.requiredInputs.forEach((property)=>{
                this.error.articulo[property] = '';
                if(isEmpty(this.articulo[property])){
                    valido = false;
                    this.error.articulo[property] = 'El campo es obligatorio';
                }
            });
            return valido
        },
        checkAndGetDocuments(doc){
            if(this.articulo.documentacion != undefined){
                if (this.articulo.documentacion[doc]!=undefined) {
                    return this.articulo.documentacion[doc].imagenes;
                }
            }
            return null
        }
		
    },
	mounted() {
        this.articulo = JSON.parse(JSON.stringify(this.articuloEdicion));
        setTimeout(() => {
            this.inputFileUpdateKey1++
            this.inputFileUpdateKey2++
            this.inputFileUpdateKey3++
            this.inputFileUpdateKey4++
        }, 1500);
    },
}
</script>

<style scoped>

</style>