<template>
    <div class="row" v-if="!trigger.show">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-1">Búsqueda de artículos</h4>
            <div class="form-row">
              <div class="col-12 col-sm-9 col-md-10  mb-2">
                <input
                  type="text"
                  class="form-control w-100"
                  v-model="query"
                  placeholder="Lavandina concent..."
                  @keyup.enter="search()"
                  @input="startSearch()"
                />
              </div>
              <div class="col-12 col-sm-3 col-md-2 mb-2">
                <button class="btn btn-success w-100" @click="search()">
                  Buscar
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                </button>
              </div>


              <!-- <div class="col-12 d-flex justify-content-between my-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="query"
                  placeholder="Lavandina concent..."
                  @keyup.enter="search()"
                  @input="startSearch()"
                />
                <button class="btn btn-success ml-2" @click="search()">
                  Buscar
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                </button>
              </div> -->
            </div>
            <!-- <div class="row mb-1"><div class="col"><h5>{{searchResults.length}} {{(searchResults.length==1) ? 'resultado' : 'resultados'}}:</h5></div></div> -->
            <div class="row mb-1" v-if="searched && query.length"><div class="col"><h5>Resultados: <div class="badge badge-light">{{searchResults.length}}</div></h5></div></div>
            <div class="row" v-if="searched && query.length">
              <div class="col-12" v-if="!searchResults.length">
                <div class="alert alert-info">No se encontraron resultados a través de la busqueda: <strong>{{query}}</strong></div>
              </div>
              <div class="col-12 mb-2" v-for="(result,i) in searchResults" :key="i">
                  <div class="border border-dark rounded p-2">
                    <div class="row align-items-center">
                      <div class="col-10 pl-3 d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                        <div class="ml-3">
                          <h5 @click.prevent="showResult(result)" class="pointer">
                            {{result.name}}
                          </h5>
                          <span class="text-muted">{{result.previewData.tipo}}</span> <span class="badge badge-primary">Marca: {{result.previewData.marca}}</span> <span class="badge badge-light">Vencimiento: {{result.previewData.vencimiento}}</span>
                        </div>
                      </div>
                      <div class="col"><button class="btn btn-primary w-100" @click.prevent="showResult(result)">Ver</button></div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="row d-flex align-items-center">
              <div class="col d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                <div class="ml-3">
                  <h4>
                    {{seleccion.name}}
                  </h4>
                  <span class="text-muted">{{seleccion.previewData.tipo}}</span>
                </div>
              </div>
              <div class="col-3 text-right">
                <button class="btn btn-light" @click="closeResult()"><span style="transform:rotate(180deg);display:inline-block; position:relative;bottom:2px">&#10132;</span> Volver a búsqueda</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <h5>Marca del artículo: {{seleccion.previewData.marca}}</h5>
            <p>Contacto: <a :href="`mailto:${marcas[seleccion.data.marca_articulo].email}`" target="_blank">{{marcas[seleccion.data.marca_articulo].email}}</a> &phone; <a :href="`tel:+${marcas[seleccion.data.marca_articulo].telefono}`" target="_blank">{{marcas[seleccion.data.marca_articulo].telefono}}</a> &#9906; <a :href="`https://www.google.com/maps/place/${marcas[seleccion.data.marca_articulo].domicilio}`" target="_blank" rel="noopener noreferrer">{{marcas[seleccion.data.marca_articulo].domicilio}}</a></p>
            
            <h5 class="mt-2">Vencimiento de aprobación: <div class="badge" :class="'badge-'+getColorStateByDate(seleccion.data.vencimiento)">{{seleccion.previewData.vencimiento}}</div></h5>
            <div class="mt-3">
              <p>
                <button v-if="seleccion.data.documentacion.ficha_tecnica != undefined" class="btn btn-primary mr-2" type="button" data-toggle="collapse" data-target="#ficha_tecnica"  aria-expanded="false" >Ficha técnica</button>
                <button v-if="seleccion.data.documentacion.hoja_seguridad != undefined" class="btn btn-primary mr-2" type="button" data-toggle="collapse" data-target="#hoja_seguridad"  aria-expanded="false" >Hoja de seguridad</button>
                <button v-if="seleccion.data.documentacion.aprobacion != undefined" class="btn btn-primary mr-2" type="button" data-toggle="collapse" data-target="#aprobacion"  aria-expanded="false" >Aprobación</button>
              </p>
              <div class="row mt-2">
                <div class="col" id="docs">
                  <div class="collapse multi-collapse" data-parent="#docs" id="ficha_tecnica" v-if="seleccion.data.documentacion.ficha_tecnica != undefined">
                    <div class="p-3 mb-5 bg-light rounded">
                      <splide :options="{rewind : true}">
                        <splide-slide v-for="(imagen,i) in seleccion.data.documentacion.ficha_tecnica.imagenes" :key="i">
                          <img :src="imagen">
                        </splide-slide>
                      </splide>                                   
                    </div>
                  </div>
                  <div class="collapse multi-collapse" data-parent="#docs" id="hoja_seguridad" v-if="seleccion.data.documentacion.hoja_seguridad != undefined">
                    <div class="p-3 mb-5 bg-light rounded">
                      <splide :options="{rewind : true}">
                        <splide-slide v-for="(imagen,i) in seleccion.data.documentacion.hoja_seguridad.imagenes" :key="i">
                          <img :src="imagen">
                        </splide-slide>
                      </splide>             
                    </div>
                  </div>
                  <div class="collapse multi-collapse" data-parent="#docs" id="aprobacion" v-if="seleccion.data.documentacion.aprobacion != undefined">
                    <div class="p-3 mb-5 bg-light rounded">
                        <splide :options="{rewind : true}">
                          <splide-slide v-for="(imagen,i) in seleccion.data.documentacion.aprobacion.imagenes" :key="i">
                            <img :src="imagen">
                          </splide-slide>
                        </splide>                         
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    

</template>

<script>
import {dateFormat} from '@/utils';
// import {url} from '../../../.env';
// import VistaEntidad from '@/components/Admision/VistaEntidad.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
// import $ from 'jquery';
// var $myGroup = $('#docs');
// $myGroup.on('show.bs.collapse','.collapse', function() {
//     $myGroup.find('.collapse.in').collapse('hide');
// });

export default {
  name: "Busqueda",
  components: {
    Splide,
    SplideSlide,
  },
  props:{articulos:Object,marcas:Object,rubros:Object},
  data: () => ({
    seleccion:null,
    trigger:{
      show:false,
    },
    query: "",
    searchResults: [],
    searched: false,
  }),
  methods: {
    getColorStateByDate(date){
      let today = new Date().getTime();
      let expireDate = new Date(parseInt(date)).getTime();
      
      let difference = (expireDate - today) / (1000*3600*24);
      // return Math.ceil(difference);

      if (difference>30) {
        return 'success'
      }else if(difference<=30 && difference>15){
        return 'warning'
      }else {
        return 'danger'
      }
    },
    showResult(result){
      this.cerrarVista();
      setTimeout(() => {
        this.trigger.show = true;
        this.seleccion = result;
      }, 200);
    },
    closeResult(){
      this.trigger.show = false;
      this.seleccion = null;
    },
    cerrarVista(){
      this.trigger.show = false;
      this.seleccion = null;
    },
    startSearch(){
      if (this.query.length) {
        setTimeout(() => {
          this.search();
        }, 500);
      }
    },
    search() {
      this.searchResults = null;
      this.searchResults = [];
      
      if (this.query.length) {
        this.searched = true;
        if(this.articulos!= null && Object.keys(this.articulos).length){
          Object.keys(this.articulos).forEach((e) => {
            if (this.articulos[e].nombre.toLowerCase().includes(this.query.toLowerCase())) {
              this.searchResults.push({
                data: this.articulos[e],
                name: `${this.articulos[e].nombre}`,
                previewData:{tipo:this.rubros[this.articulos[e].rubro].nombre, marca: this.marcas[this.articulos[e].marca_articulo].nombre, vencimiento: dateFormat(this.articulos[e].vencimiento)},
              })
            }
          });
        }

      }
    },
  },
  mounted() {
    // getDataFrom('articulos',url).then((response)=>{
    //     if(response!=null){
    //     this.articulos = response;
    //     this.trigger.listar = true;
    //     }else {
    //     alert("Ocurrio un error, actualice la página");
    //     console.warn("No se obtuvo ningún resultado a través de la api maquinas")
    //     }
    // })
    // .catch((err)=>{
    //     alert("Ocurrio un error, actualice la página");
    //     console.warn(err)
    // })
  },
};
</script>
