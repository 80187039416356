<template>
    <div v-if="trigger.save">
        <div class="w-100 py-5 mx-auto text-center">
            <div class="d-flex justify-content-center">
                <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <h4 class="mt-3">Actualizando los sectores</h4>
            <p>Sea paciente, no demora mucho</p>
            <!-- Spinner de carga -->
        </div>
    </div>
    <div v-if="trigger.show">
        <div class="w-100 py-5 mx-auto text-center text-success">
            <h4>&check; Sectores actualizados</h4>
            <!-- <p class="text-dark">Serás redirigido en 1 segundos</p> -->
        </div>
    </div>
    <form v-if="trigger.write && rubroEdicion">
        <h4 class="mb-3">Administrar los sectores del rubro {{rubro.nombre}}</h4>
        <label class="form-label">Agregar un nuevo sector</label>
        <div class="form-row mb-3">
            <div class="col-12 col-md-8 mb-2">
                <input type="text" class="form-control" v-model="nuevoSector.nombre" @input="error.nuevoSector.nombre=''" placeholder="Nombre del sector.."/>
                <div class="text-danger mt-1" v-if="error.nuevoSector.nombre.length">&otimes; {{error.nuevoSector.nombre}}</div> 
            </div>
            <div class="col-12 col-md-4 mb-2">
                <!-- <button class="btn btn-md btn-light mr-2" @click.prevent="$emit('cerrar',false)">Cancelar</button> -->
                <button class="btn btn-md btn-primary w-100" @click.prevent="agregar()">Agregar sector</button>
            </div>
        </div>
        <h5 class="mb-3">Configurar los sectores existente</h5>
        <div class="row">
            <div class="col-12 mb-2" v-for="(sector,i) in rubroEdicion.sectores" :key="i">
                <div class="form-row">
                    <div class="col-8 d-flex">
                        <input type="text" class="form-control mr-2" v-model="sector.nombre" @keyup.enter="editar(i)">
                        <button class="btn btn-primary mr-2" @click.prevent="editar(i)">Editar</button>
                    </div>
                    <div class="col pl-1">
                        <button class="btn btn-danger" @click.prevent="eliminar(i)">Eliminar</button>
                    </div>
                </div>
            </div>


            <!-- <div class="col">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(sector,i) in rubroEdicion.sectores" :key="i">
                            <td>
                                <input type="text" class="form-control" v-model="sector.nombre" @keyup.enter="editar(i)">
                            </td>
                            <td>
                                <button class="btn btn-primary mr-2" @click.prevent="editar(i)">Editar</button>
                                <button class="btn btn-danger" @click.prevent="eliminar(i)">Eliminar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
        </div>
        <div class="text-danger" v-if="error.eliminar.length">&otimes; {{error.eliminar}}</div> 
        <div class="row">
            <div class="col-12">
                <div class="alert alert-info">Es necesario guardar los cambios para que estos se efectuen.</div>
            </div>
            <div class="col text-right">
                <button class="btn btn-md btn-light" @click.prevent="$emit('cerrar',false)">Cancelar</button>
                <button class="btn btn-md btn-success ml-2" @click.prevent="update()">Guardar cambios</button>
            </div>
        </div>
    </form>	
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

import { url } from "../../../../.env";
import { setProp, getDataFrom } from "@/utils";


export default {   
	name: 'CrearTipo',
    props:{rubro:Object},
    data() {
        return {
			nuevoSector: {
                nombre: null,
                timestamp: new Date().getTime(),
                nuevo:true,
            },
            rubroEdicion:null,
            sectoresEliminados:[],
            
			error:{
                nuevoSector:{
                    nombre:''
                },
                eliminar:'',
            },
            ultimo_id: null,
            trigger: {
                write: true,
                save: false,
                show: false,
            }
        }
    },
    methods: {
        editar(index){
            this.error.eliminar = '';
            if (this.rubroEdicion.sectores[index].nuevo == undefined) {
                if (this.rubroEdicion.sectores[index].nombre === '') {
                    this.rubroEdicion.sectores[index].nombre = this.rubro.sectores[index].nombre;
                }else {
                    setProp(this.rubroEdicion.sectores[index],'editado',true);
                    this.rubroEdicion.sectores[index].timestamp = new Date().getTime();
                }
                this.$forceUpdate();
            }
        },
        eliminar(index){
            this.error.eliminar = '';
            if (Object.keys(this.rubroEdicion.sectores).length == 1) {
                this.error.eliminar = 'El rubro debe contener al menos 1 sector.';
                return null;
            }
            if (this.rubroEdicion.sectores[index].nuevo != undefined && this.rubroEdicion.sectores[index].nuevo == true) {
                delete this.rubroEdicion.sectores[index];
            }else {
                this.sectoresEliminados.push(index);
                delete this.rubroEdicion.sectores[index];
            }
            this.$forceUpdate();
        },
        agregar(){
            this.error.eliminar = '';
            this.error.nuevoSector.nombre = '';
            // Se comprueba que no exista un rubro de igual nombresi es que existen sectores en el rubro
            if (this.rubroEdicion.sectores!=undefined) {
                let valido = true
                Object.keys(this.rubroEdicion.sectores).forEach((s)=>{
                    if(this.rubroEdicion.sectores[s].nombre.toLowerCase() == this.nuevoSector.nombre.toLowerCase()){
                        valido = false
                    }
                })
    
                if(!valido){
                    this.error.nuevoSector.nombre = 'El rubro existe, no se creará';
                    return null;
                }
            }else{
                setProp(this.rubroEdicion,'sectores',{})
            }

            // Se agrega en el rubro en Edicion. Este objeto se recorrerá para pushear, updatear o deletear
            setProp(this.rubroEdicion.sectores, Object.keys(this.rubroEdicion.sectores).length, this.nuevoSector);
            this.nuevoSector= {
                nombre: null,
                timestamp: new Date().getTime(),
                nuevo:true,
            };
        },
		update(){
            if(this.error.eliminar.length) return null

            this.trigger.write = false;
            this.trigger.save = true;
            // Cada una de las acciones de actualizacion (CREATE, UPDATE y DELETE) se realizan mediante una promesa
            // Por lo tanto estas se referencian a partir del siguiente array
            let promises = [];
            // Se recorren los sectores del rubro en edicion
            Object.keys(this.rubroEdicion.sectores).forEach(s => {
                // Create
                if (this.rubroEdicion.sectores[s].nuevo != undefined) {
                    let promise = new Promise((resolve,reject)=>{
                        delete this.rubroEdicion.sectores[s].nuevo
                        firebase
                        .database()
                        .ref(`/articulos_rubro/${this.rubro.id}/sectores`)
                        .push(this.rubroEdicion.sectores[s])
                        .then(()=>{resolve()})
                        .catch((err)=>{reject(err)})
                    })
                    promises.push(promise);
                // Update
                }else if (this.rubroEdicion.sectores[s].editado != undefined) {
                    let promise = new Promise((resolve,reject)=>{
                        delete this.rubroEdicion.sectores[s].editado
                        firebase
                        .database()
                        .ref(`/articulos_rubro/${this.rubro.id}/sectores/${s}`)
                        .update(this.rubroEdicion.sectores[s])
                        .then(()=>{resolve()})
                        .catch((err)=>{reject(err)})
                    })
                    promises.push(promise);
                }
            });
            // Delete
            if (this.sectoresEliminados.length) {
                this.sectoresEliminados.forEach((sId)=>{
                    // Se elimina el sector
                    console.log(sId);
                    promises.push(
                        new Promise((resolve,reject)=>{
                            firebase
                            .database()
                            .ref(`/articulos_rubro/${this.rubro.id}/sectores/${sId}`)
                            .remove()
                            .then(()=>{resolve()})
                            .catch((err)=>{reject(err)})
                        })
                    )
                    // Luego se elimina el sector en todos los articulos que pertenezcan a este sector
                    // Por lo tanto los articulos tendrán sector indefinido, se listaran a parte en la lista
                    getDataFrom(`articulos.json?orderBy="rubro_sector"&startAt="${sId}"&print=pretty`,url)
                    .then(articulos => {
                        if (articulos) {
                            Object.keys(articulos).forEach((artId)=>{
                                promises.push(
                                    new Promise((res,rej)=>{
                                        firebase
                                        .database()
                                        .ref(`/articulos/${artId}`)
                                        .update({rubro_sector:null})
                                        .then(()=>{res()})
                                        .catch((err)=>{rej(err)})
                                    })
                                );
                            })
                            
                        }
                    })
                    .catch(err => {
                        console.error(err); 
                    })
                })
            }
            // Cuando todas las promesas se cumplan, termina la actualizacipn
            Promise.all(promises).then(()=>{
                this.trigger.save = false;
                this.trigger.show = true;
                setTimeout(() => {
                    this.$emit('cerrar',true);
                }, 2000);
            })
            .catch((err)=>{
                console.warn(err);
                alert("Se produjo un error al actualizar el rubro, pruebe actualizar la página y reintentar.")
            });

            // firebase.database().ref(`/articulos_rubro/${this.rubro.id}/sectores`)
            // .push(this.sector)
            // .then(()=>{
            //     this.trigger.save = false;
            //     this.trigger.show = true;
            //     setTimeout(() => {
            //         this.$emit('cerrar',true);
            //     }, 2000);
                
            // })
            // .catch((err)=>{
            //     console.warn(err);
            //     alert("Se produjo un error al crear el sector, pruebe actualizar la página y reintentar.")
            // })
		},
		
    },
	mounted() {
        this.rubroEdicion = JSON.parse(JSON.stringify(this.rubro));
        // this.rubroEdicion = Object.create(this.rubro);
        
    },
}
</script>

<style scoped>

</style>