<template>
  <h4 class="mb-3">Lista de rubros existentes</h4>
  <div class="table-responsive">
    <table id="tabla" class="table">
      <thead>
        <tr>
          <th>Rubro</th>
          <th>Sectores</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(rubro,i) in rubros" :key="rubro.id">
          <td>{{capitalizeR(rubro.nombre)}}</td>
          <td>
            <div class="badge badge-light mr-2" v-for="(sector,i) in rubro.sectores" :key="i">
            {{sector.nombre}}
            </div>
            <!-- <div class="badge badge-light d-block mb-2 d-flex align-items-center justify-content-between" v-for="(sector,i) in rubro.sectores" :key="i">
            &bull; {{sector.nombre}}
            <button class="border-0 rounded bg-primary p-1 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
              Artículos
            </button>
            </div> -->
          </td>
          <td class="text-right">
              <button class="btn btn-primary mr-2" @click.prevent="prepararEdicion(rubro,i,'administrar-sectores')">Administrar sectores</button>
              <button class="btn btn-primary mr-2" @click.prevent="prepararEdicion(rubro,i,'editar')">Editar rubro</button>
              <!-- <button class="btn btn-success">Agregar rubro</button> -->
          </td>
          
        </tr>
      </tbody>
    </table>
  </div>

   <!-- <modal-prompt :type="'primary'" v-if="trigger.crear">
      <template v-slot:title><svg xmlns="http://www.w3.org/2000/svg" style="position: relative; bottom: 2px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
      &nbsp;Añadir sector en rubro: {{capitalizeR(seleccion.nombre)}}</template>
      <template v-slot:body>
        <crear-sector :rubro="seleccion" @cerrar="cerrar($event)"></crear-sector>
      </template>
    </modal-prompt> -->
</template>

<script>
import $ from 'jquery';
import {tableConfig} from '../../../.env';
import {capitalize,setProp} from '@/utils.js';

// import ModalPrompt from '@/components/ModalPrompt.vue';
// import CrearSector from '@/components/Articulo/forms/CrearSector.vue';
export default {
    name:'ListaTipos',
    props:{rubros:Object},
    // components: {ModalPrompt,CrearSector},
    data(){
      return {
        seleccion:null,
        trigger:{
          crear:false,
        }
      }
    },
    mounted(){
      $('#tabla').dataTable(tableConfig)
    },
    methods:{
      capitalizeR(str){return capitalize(str)},
      prepararEdicion(rubro,i,accion){
        let rubroConId = rubro;
        setProp(rubroConId,'id',i);
        this.$emit(accion,rubroConId);
      },
      cerrar(actualizacion){
        if (actualizacion) {
          this.trigger.crear = false;
          this.seleccion = null;
        }else {
          this.trigger.crear = false;
          this.seleccion = null;
        }
      }
    }
}
</script>

<style scoped>
.modal-mask {
    cursor: default;
}
</style>