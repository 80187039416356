<template>
    <div class="page-content">
      <!-- Breadcrumb -->
      <nav class="page-breadcrumb" id="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">{{breadcrumbA}}</li>
          <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
        </ol>
      </nav>
      <!-- Card view title and description -->
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h6 class="card-title">{{ breadcrumbB }}</h6>
              <div class="d-flex align-items-center justify-content-between">
                <p class="card-description w-50 p-0 m-0">{{ descripcion }}</p>
                <div class="" v-if="role.editor">
                  <button
                    v-on:click="refrescarTriggers('listar')"
                    type="button"
                    class="btn btn-light"
                    v-if="trigger.crear"
                  >Cancelar la creación</button>
                  <button
                    v-on:click="refrescarTriggers('crear')"
                    type="button"
                    class="btn  btn-success ml-1"
                    v-if="!trigger.crear"
                  >Añadir artículo</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12 mb-3" v-if="trigger.buscador && articulos">
          <buscador :rubros="rubros" :marcas="marcas" :articulos="articulos"></buscador>
        </div>
        <div class="col-12 mb-3" v-if="trigger.crear">
          <div class="card">
            <div class="card-body">
              <crear-articulo :rubros="rubros" :marcas="marcas" :articulos="articulos" @cerrar="cerrar($event)"></crear-articulo>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3" v-if="trigger.editar">
          <div class="card">
            <div class="card-body">
              <editar-articulo :rubros="rubros" :marcas="marcas" :articuloEdicion="seleccion" @cerrar="cerrar($event)"></editar-articulo>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3" v-if="trigger.listar && role.editor">
          <div class="card">
            <div class="card-body">
              <lista-articulos :rubros="rubros" :marcas="marcas" :articulos="articulos" @cerrar="cerrar($event)" @editar="realizarAccion($event,'editar')" @actualizar="cerrar(true)"></lista-articulos>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- <modal-prompt :type="'warning'" v-if="trigger.registrarEstado">
      <template v-slot:title><svg xmlns="http://www.w3.org/2000/svg" style="position: relative; bottom: 2px;" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>&nbsp;Registrar estado de máquina</template>
      <template v-slot:body>
        <actualizar-estado :maquinaOriginal="seleccion" @cerrar="cerrar($event)"></actualizar-estado>
      </template>
    </modal-prompt> -->
</template>
<script>
import CrearArticulo from '@/components/Articulo/forms/CrearArticulo.vue';
import EditarArticulo from '@/components/Articulo/forms/EditarArticulo.vue';

// Lista
import ListaArticulos from '@/components/Articulo/ListaArticulos.vue';
import Buscador from '@/components/Articulo/Buscador.vue';

// import ModalPrompt from '@/components/ModalPrompt.vue';
import {getDataFrom} from '@/utils';
import {url} from '../../.env';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
  name: 'Articulos',
  components: {
    CrearArticulo,
    ListaArticulos,
    Buscador,
    EditarArticulo
  },
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Artículos',
      descripcion:'Esta sección permite llevar un control tanto de la documentación de los artículos, como de la información de sus marcas ',
      rubros:null,
      marcas:null,
      articulos:null,
      trigger: {
        crear: false,
        editar: false,
        listar: false,
        buscador:true,
      },
      seleccion: null,
      role:{editor:false},
  
  }),
  methods: {
    refrescarTriggers(activo) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(activo!=undefined) this.trigger[activo] = true
    },
    cerrar(actualizar) {
        this.refrescarTriggers('listar');
        if (actualizar) {
            // Obtener devuelve como promesa truo o false en caso de devolver datos.
            this.trigger.listar = false;
            // TODO Mejorar la logica, se deberia crear la funcion obtener para llamar.
            
            getDataFrom('articulos',url).then((response)=>{
              if(response) {
                this.articulos = response;
              }else{
                this.articulos= null
              }
              this.trigger.listar = true;
            })
            .catch((err)=>{
              console.warn(err)
              alert("Ocurrio un error, actualice la página");
            })
        }
    },
    // TODO generalizar metodo junto a refrescar
    realizarAccion(seleccion,accion){
      this.seleccion = seleccion;
      this.refrescarTriggers(accion);
    },

    
  },
  mounted(){
    getDataFrom('articulos_rubro',url).then((response) => {
      this.rubros = response;

      getDataFrom('articulos_marca',url).then((response) => {
        this.marcas = response;

        getDataFrom('articulos',url).then((response) => {
          this.articulos = response;
          this.trigger.listar = true;
        })
      })
    })

    let permission = userPermission.find(record => record.ModulosAccess === "Calidad")
    this.role.editor = (permission!=undefined) ? permission.Editor : false;


    // getDataFrom('maquinas',url).then((response)=>{
    //   if(response!=null){
    //     this.maquinas = response; 

    //     getDataFrom('sectores',url).then((data)=>{
    //         if(data != null){
    //             this.sectores = {};
    //             Object.keys(data).forEach((e)=>{
    //               console.log(data[e]);
    //               setProp(this.sectores, data[e].id, data[e]);
    //             })
    //             this.trigger.panelControl  = true;
    //             this.trigger.listar = true;
    //         }else{
    //             // TODO Manejar el error creando un ir aleatorio
    //             console.warn("No se encontraron sectores");
    //             alert("Se produjo un erro al crear la maquina, actualice la página y reintente.")
    //         }
    //     })
    //     .catch((err)=>{
    //         console.warn(err);
    //         alert("Se produjo un erro al crear la maquina, actualice la página y reintente.")
    //     })
    //   }else {
    //     this.trigger.listar = true;
    //     // alert("Ocurrio un error, actualice la página");
    //     console.warn("No se obtuvo ningún resultado a través de la api maquinas")
    //   }
    // })
    // .catch((err)=>{
      
    //   alert("Ocurrio un error, actualice la página");
    //   console.warn(err)
    // })



    // this.obtener('maquinas').then((result)=>{
    //   this.trigger.listar = true
    // })



  }
};

</script>